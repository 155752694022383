
import React from "react";
import './AdvanceFilter.css';
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { apiurl } from "../../App";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  
  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        <CloseIcon className="modalcomp_closeicon" onClick={onClose} 
        style={{left:(localStorage.nurse_language == 'ar'?'3%':'' ) }}
        />
      </MuiDialogTitle>
    );
  });
  
  const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    }))(MuiDialogContent);

export default class AdvanceFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Nurse:[],
            selected_nurseId:0,
            selected_working_hrs:"",
            
            Duty_Hours:[{
              id:"08:00",
              name:"08:00"
            },
            {
              id:"12:00",
              name:"12:00"
            }]
        }
    }


    componentDidMount ()
    {
      this.GetNurse();
    }

    

    GetNurse = () =>{
        axios({
            method: 'post',
            url:apiurl + 'Nurse/getnurse', 
            data:{
                "nursevendorId":localStorage.getItem("nurse_vendor_id")? localStorage.getItem("nurse_vendor_id"):"12"
            }
        }).then((response) => {
          this.setState({Nurse:response.data.data})
        })  
    }



    changeDynamic = (data, key) => { 
         
       if(key=='nurse')
       {
        this.setState({selected_nurseId:data});
       }
       else{
        this.setState({selected_working_hrs:data});
       }
        
    }


    submitFilter ()
    {
      this.props.submitAdvanceFilter(this.state.selected_nurseId,this.state.selected_working_hrs)
    }

    render ()
    {
      const {t}= this.props
        return(
        <Dialog
        className={`${this.props.i18n.language=='ar'?"arabic_cls_filter":""} Dialogmodal filter_dialogue`}
          // onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
          disableBackdropClick={true}
          maxWidth={"xs"}

        >

         <DialogTitle id="customized-dialog-title" className="modal_head" onClose={this.props.onClose} >
            <span style={this.props.i18n.language === "ar"? {display:"flex",flexDirection:"row-reverse"}: {}} className={`${this.props.clrchange} modal_head`}>{t("AdvanceFilter")}</span>
          </DialogTitle>
          <DialogContent dividers className="DialogContent">
           
               <Grid container spacing={3}
                className={`${this.props.i18n.language=='ar'?"arabic_cls":""} pl-5 pr-5 pt-2 advance_filter_main_div`}>
                  
                        <Grid item md={12} sm={12} style={{paddingBottom:'0px',margin:'auto'}}>
                            <Labelbox
                                className="training_adjust"
                                style={{}}
                                labelname={t("tbl_head_nurse")}
                                type="select"
                                placeholder={t("select_Nurse")}
                                dropdown={this.state.Nurse}
                                valuelabel={"name"}
                                valuebind={"NurseId"}
                                changeData={(data) => this.changeDynamic(data, 'nurse')}
                                value={this.state.selected_nurseId}
                            />
                        </Grid>
                        <Grid item md={12} sm={12} style={{paddingBottom:'0px',margin:'auto'}}>
                            <Labelbox
                                className="training_adjust "
                                labelname={t("tbl_head_duty_hours")}
                                type="select"
                                placeholder={t("select_Duty_Hours")}
                                dropdown={this.state.Duty_Hours}
                                valuelabel={"name"}
                                valuebind={"id"}
                                changeData={(data) => this.changeDynamic(data, 'duty_hours')}
                                value={this.state.selected_working_hrs}
                            />
                        </Grid>

                        <Grid  item md={12} sm={12} className=" button_container">
                            <Button className="FilterSubmit" onClick={()=>{this.submitFilter()}}>{t("submit")}</Button>
                        </Grid>
                   
               </Grid>
          </DialogContent>
        </Dialog>
        )
        
    }
}