import React from "react";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import "./Nurse_dash_details.css";
import Button from "@material-ui/core/Button";
import dateFormat from "dateformat";
import { NavLink } from "react-router-dom";
import NurseView from "../NurseView/Nurse_view";
import NurseLocationModal from "./NurseLocationModal";

const current_date = dateFormat(new Date(), "dd-mmm-yy");
class DashboardTable extends React.Component {
  state = {
    openview: false,
    viewdata: "",
    EditData: "",
    EditData: [], //suriya i/p
    locationopen: false,
    locationdata: [],
    is_show_legend: false
  };

  createData = (parameter) => {
    var keys = Object.keys(parameter);
    var values = Object.values(parameter);

    var returnobj = {};

    for (var i = 0; i < keys.length; i++) {
      returnobj[keys[i]] = values[i];
    }
    return returnobj;
  };

  modelopen = (data, id) => {
    if (data === "view") {
      this.setState({
        openview: true,
        EditData: this.props.ViewData[id]
      });
    } else if (data === "edit") {
      this.setState({ editopen: true });
    } else if (data === "location") {
      this.setState({
        locationopen: true,
        locationdata: this.props.ViewData[id],
      });
    }
  };

  componentWillUnmount = () => {
    this.setState({ is_show_legend: current_date == dateFormat(this.props.startDate, "dd-mmm-yy") && current_date == dateFormat(this.props.endDate, "dd-mmm-yy") ? true : false })
  console.log("jim",this.props.TableData)
  }
  closemodal = () => {
    this.setState({ openview: false, editopen: false, locationopen: false });
  };

  render() {
    console.log(current_date)
    console.log(dateFormat(this.props.startDate, "dd-mmm-yy"))
    const { t } = this.props

    const heading = ((localStorage.nurse_language == 'ar') ?
      [
        { id: "", label: t('tbl_head_remarks') },
        // { id: "check_in_time", label: t("tbl_head_check_in_time") },
        { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
        // { id: "status", label: t("tbl_head_status") },
        { id: "to_date", label: t("tbl_head_to") },
        { id: "from_date", label: t('tbl_head_from') },
        { id: "dutyhours", label: t("tbl_head_duty_hours") },
        { id: "nursename", label: t("tbl_head_nurse") },
        { id: "paymentstatus", label: t("paymentStatus") },
        { id: "paymenttype", label: t("paymentType") },
        { id: "appointmenttype", label: t("appointmentType") },
        { id: "customer", label: t('tbl_head_member') },
        { id: "", label: t("tbl_head_s_no") },
      ] : [
        { id: "", label: t("tbl_head_s_no") },
        { id: "customer", label: t('tbl_head_member') },
        { id: "appointmenttype", label: t("appointmentType") },
        { id: "paymenttype", label: t("paymentType") },
        { id: "paymentstatus", label: t("paymentStatus") },
        { id: "nursename", label: t("tbl_head_nurse") },
        { id: "dutyhours", label: t("tbl_head_duty_hours") },
        { id: "from_date", label: t('tbl_head_from') },
        { id: "to_date", label: t("tbl_head_to") },
        // { id: "status", label: t("tbl_head_status") },
        { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
        // { id: "check_in_time", label: t("tbl_head_check_in_time") },
        { id: "", label: t('tbl_head_remarks') },
      ])
    return (
      <div>
        {/* {current_date == dateFormat(this.props.startDate, "dd-mmm-yy") && current_date==dateFormat(this.props.endDate, "dd-mmm-yy") && <div style={{display:'flex',marginTop:'2rem'}}>
            <div  className={`${this.props.i18n.language=='ar'?"order_1":""} order_complete_legend`}></div>
            <div style={{color: 'black',marginLeft: '10px',fontSize: '16px',fontWeight: '400'}} className={`${this.props.i18n.language=='ar'?"order_0":""}`}> {t('bookings_completed_yesterday')} </div>
            <div className={`${this.props.i18n.language=='ar'?"order_3":""} order_starting_legend`}></div>
            <div style={{color: 'black',marginLeft: '10px',fontSize: '16px',fontWeight: '400'}} className={`${this.props.i18n.language=='ar'?"order_2":""}`}> {t('bookings_starting_tomorrow')} </div>
        </div>} */}
        <div className="nurse_dash_sidehead">
          {(localStorage.nurse_language == 'ar') ? [
            <div class="todaywwe">

              <text className="todays_appointments_text">
                {t('today_bookings')}
              </text>
              <span className={` ${this.props.i18n.language == "ar" ? "ar_todaydate" : ""} todayDate`}> {current_date} </span>
            </div>
          ] : [
            <div>
              <text className="todays_appointments_text">
                {t('today_bookings')}
              </text><span> {current_date} </span>
            </div>
          ]}

        </div>

        <div className="nurse_dashboard_table_div">
          <Tablecomponent
            heading={heading}
            rowdata={this.props.TableData}
            props_loading={this.props.props_loading}
            tableicon_align={" "}
            DeleteIcon="close"
            EditIcon="close"
            HistoryIcon="close"
            GrandTotal="close"
            modelopen={(e, id) => this.modelopen(e, id)}
            t={t}
            i18n={this.props.i18n}
          />
        </div>

        {/* 
        <div className="page_button_container">
          <div>
            <Button
              component={NavLink}
              to="/Home/availability"
              className="lab_dash_bottom_buttons lab_dash_bottom1"
            >
              Availability Calendar
            </Button>
            <Button
              component={NavLink}
              to="/Home/customerhistory"
              className="lab_dash_bottom_buttons lab_dash_bottom2"
            >
              Customer History
            </Button>
            <Button
              component={NavLink}
              to="/Home/advertisement"
              className="lab_dash_bottom_buttons lab_dash_bottom3"
            >
              Advertisement Booking
            </Button>
          </div>
        </div> */}

        {/* <Modalcomp
          visible={this.state.openview}
          title={"View details"}
          closemodal={e => this.closemodal(e)}
        >
        </Modalcomp> */}

        <NurseView
          open={this.state.openview}
          onClose={this.closemodal}
          ViewData={this.state.EditData}
          is_from_manage_nuser="true"
          t={t}
          i18n={this.props.i18n}
        />

        <Modalcomp
          visible={this.state.locationopen}
          title={this.state.locationdata.Nursename?.toUpperCase() + " - NURSE TRACKING"}
          closemodal={(e) => this.closemodal(e)}
          modelwidthClass="nurse_location_modal_div"
          clrchange="textclr"
          t={t}
          i18n={this.props.i18n}
        // xswidth={"xs"}
        >
          <NurseLocationModal locationdata={this.state.locationdata} t={t} i18n={this.props.i18n} />
        </Modalcomp>

        {/* <Modalcomp
          visible={this.state.editopen}
          title={"Edit details"}
          closemodal={e => this.closemodal(e)}
          xswidth={"xs"}
        ></Modalcomp> */}
      </div>
    );
  }
}

export default DashboardTable;
