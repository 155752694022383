import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import Button from "@material-ui/core/Button";
import { Tabs } from "antd";
import Checkbox from "@material-ui/core/Checkbox";
import "./BookingDetails.css";
import DealList from "./DealList";
import Calendar from "./Calendar";
import Axios from "axios";
import { apiurl } from "../../App";
import ValidationLibrary from "../../helpers/validationfunction";
import dateformat from "dateformat";
import { Select } from "antd";
import moment from "moment";
import ShowNotification from "../ShowNotification/ShowNotification";
import { formatMoney } from "../../App";

const { Option } = Select;
const getTomorrowDate = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow;


};

export default class BookingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      serviceType: [],
      serviceTypeAll: false,
      edit: false,
      activeKey: "1",
      dealOption: "F",
      dutyOption: "08:00",
      deal_valid_from: getTomorrowDate(),
      deal_valid_to: getTomorrowDate(),
      dealActive: true,
      afteredit: false,
      calendarPage: true,
      valideToerror: false,
      dateError: false,
      fieldreq: false,
      services: [],
      serviceTypeValue: "All",
      bookingDetails: {
        package: {
          value: "",
          validation: [{ name: "required" }],
          error: false,
          errmsg: null,
        },
        deal_title: {
          value: "",
          validation: [{ name: "required" }],
          error: false,
          errmsg: null,
        },
        deal_amt: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
      },
      billingAmount: 0,
      selectedNurse: null,
      packageCost: 0,
      billingAmountError: false,
      visible: false,
      successmsg: "",
      issuccess: false,
      isTrue: false,
      isTrue12: false,
      dutyHours: "",
      fromDate: new Date(),
      toDate: new Date(new Date().setDate(new Date().getDate() + 1)), // Tomorrow's date
    };
  }

  repeat_ad = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    this.setState({
      fromDate: today,
      toDate: tomorrow,
    }, () => {
      // Optionally call getAddSlots with the new dates
      this.getAddSlots(this.state.fromDate, this.state.toDate);
    });
  };


  callback = (key) => {
    this.setState({
      activeKey: key,
    });
  };
  changeTabFun = (data) => {
    var deal_valid_from = moment(data.deal_valid_from);
    var deal_valid_to = moment(data.deal_valid_to);
    var today = moment();

    if (
      today.diff(deal_valid_from, "days") > 0 &&
      today.diff(deal_valid_to, "days") > 0 &&
      !data.isrepeat
    ) {
      this.handleopenSuccess("Deals expired", false);
    } else if (
      today.diff(deal_valid_from, "days") > 0 &&
      today.diff(deal_valid_to, "days") < 0 &&
      !data.isrepeat
    ) {
      this.handleopenSuccess("Deals already posted", false);
    } else {
      if (data.nurse_duty_hrs == "08:00") {
        this.setState({
          isTrue12: false,
          isTrue: true
        });
      } else {
        this.setState({
          isTrue12: true,
          isTrue: false
        });
      }
      this.setState(
        {
          activeKey: "1",
          editData: data,
          edit: data.isrepeat ? false : true,
        },
        () => {
          this.setState(
            {
              bookingDetails: {
                package: {
                  ...this.state.bookingDetails.package,
                  value: parseInt(data.deal_service_type_id || 0),
                },
                deal_title: {
                  ...this.state.bookingDetails.deal_title,
                  value: data.deal_title,
                },
                deal_amt: {
                  ...this.state.bookingDetails.deal_amt,
                  value: data.deal_amount,
                },
              },
              dealActive: data.deal_active === 1 ? true : false,
              dealOption: data.deal_options === "Amount" ? "M" : "F",
              dutyOption: data.nurse_duty_hrs,
              deal_valid_from: data.isrepeat
                ? dateformat(getTomorrowDate(), "yyyy-mm-dd")
                : dateformat(data.deal_valid_from, "yyyy-mm-dd"),
              deal_valid_to: data.isrepeat
                ? dateformat(getTomorrowDate(), "yyyy-mm-dd")
                : dateformat(data.deal_valid_to, "yyyy-mm-dd"),
            },
            () => this.updatePackageCostOnEdit()
          );
        }
      );
    }
  };

  /* svalue = (data) => {
    let wtf = data.deal_service_type == "" ? "All" : data.deal_service_type;
    wtf == "All"
      ? this.setState({ serviceTypeAll: false })
      : this.setState({ serviceTypeAll: true });
    this.setState({
      serviceTypeValue: wtf,
      servicetype: this.state.editData.deal_service_type_id,
    });
  }; */

  compareDate = () => {
    if (
      dateformat(this.state.deal_valid_from, "mm-dd-yyyy") <=
      dateformat(this.state.deal_valid_to, "mm-dd-yyyy")
    ) {
      this.setState({ dateError: false });
    } else {
      this.setState({ dateError: true });
    }
  };

  componentWillMount() {
    this.setState({ isTrue: true })
    localStorage.setItem("InsertDeal", 0);
    this.getServiceType();
    console.log("wrestle", this.props.edit_booking_data.nurse_duty_hrs);
    if (Object.getOwnPropertyNames(this.props.edit_booking_data).length > 0) {
      this.changeTabFun(this.props.edit_booking_data);
    }
  }

  getServiceType = () => {
    Axios({
      method: "POST",
      url: apiurl + "nurse/getNurseDetails",
      data: {
        vendorId: localStorage.getItem("nurse_vendor_id") || "12",
      },
    })
      .then((response) => {
        this.setState(
          {
            serviceType: response.data.data.map((val) => {
              return {
                id: val.nurseId,
                serviceType: val.name,
                cost_eight_hours: val.cost_eight_hours,
                cost_twelve_hours: val.cost_twelve_hours,
              };
            }),
          },
          () => this.updatePackageCostOnEdit()
        );

        this.setState({});
      })
      .catch((error) => { });
  };

  updatePackageCostOnEdit = () => {
    debugger;
    if (
      this.state.bookingDetails &&
      this.state.bookingDetails.package &&
      this.state.bookingDetails.package.value
    ) {
      const packageItem = this.state.serviceType.find(
        (k) =>
          k.id === parseInt(this.props.edit_booking_data.deal_service_type_id)
      );
      if (packageItem && packageItem.id) {
        this.setState({ selectedNurse: packageItem }, () => this.calcBillingAmount());
      }
    }
  };

  checkValidation = () => {
    var bookingDetails = this.state.bookingDetails;
    var bookingKeys = Object.keys(bookingDetails);
    for (var i in bookingKeys) {
      var errorcheck = ValidationLibrary.checkValidation(
        bookingDetails[bookingKeys[i]].value,
        bookingDetails[bookingKeys[i]].validation
      );
      bookingDetails[bookingKeys[i]].error = !errorcheck.state;
      bookingDetails[bookingKeys[i]].errmsg = errorcheck.msg;
    }
    var filtererr = bookingKeys.filter(
      (obj) => bookingDetails[obj].error == true
    );

    if (filtererr.length > 0 ||
      this.state.dateError ||
      this.state.billingAmount <= 10
    ) {
      console.log('wwwwqqqqq');
      this.setState({ error: true });
    } else {
      console.log('wwwwssssssss');
      this.setState({ error: false });
      if (!this.fieldreq) {
        this.onSubmitData();
      }
    }
    this.setState({ bookingDetails });
  };

  changeDynamic = (data, key) => {
    var bookingDetails = this.state.bookingDetails;
    var errorcheck = ValidationLibrary.checkValidation(
      data,
      bookingDetails[key].validation
    );
    bookingDetails[key].value = data;
    bookingDetails[key].error = !errorcheck.state;
    bookingDetails[key].errmsg = errorcheck.msg;
    this.setState({ bookingDetails });
    // if (key === "service_type" && data === 1) {
    //   var Data = [];
    //   this.state.serviceType.map((val) => val.id > 1 && Data.push(val.id));
    //   this.setState({
    //     serviceTypeAll: Data.toString(),
    //   });
    // }

    if (key === "package") {
      const costItem = this.state.serviceType.find((item) => item.id === data);
      this.setState({ selectedNurse: costItem }, () => {
        this.calcBillingAmount();
      });
    } else {
      this.state.selectedNurse && this.calcBillingAmount();
    }
  };

  validateInput = (data, key) => {
    // Regular expression pattern for the allowed formats
    const pattern = /^(\d{1,3})(\.\d{1,3})?$/;

    // Test the input against the pattern
    var bookingDetails = this.state.bookingDetails;
    if (pattern.test(data)) {
      console.log("Valid input:", data);
      var errorcheck = ValidationLibrary.checkValidation(
        data,
        bookingDetails[key].validation
      );
      bookingDetails[key].value = data;
      bookingDetails[key].error = !errorcheck.state;
      bookingDetails[key].errmsg = errorcheck.msg;
      this.setState({ bookingDetails });
      this.state.selectedNurse && this.calcBillingAmount();
      // return true;
    } else {
      if(data == ""){
        bookingDetails[key].value = data;
      }
      console.log("Invalid input:", data);
      // return false;
    }


  }



  activeChange(event) {
    debugger;
    // this.state.dutyHours = "";
    // console.log("wow", event.target.checked);
    if (event == true) {
      this.setState({
        dealActive: true
      });
    } else if (event == false) {
      this.setState({
        dealActive: false
      });
    }


  }

  dynamiChange(event) {
    debugger;
    // this.state.dutyHours = "";
    console.log("wow", event);
    if (this.state.selectedNurse != null && event == true) {
      this.state.dutyHours = "08:00";
      this.setState({

        isTrue12: false,
        isTrue: true
      });
      this.calcBillingAmount();
    }

    console.log("local", this.state.dutyHours, this.state.isTrue);
  }

  dynamiChange2(event) {
    debugger;
    // this.state.dutyHours = "";
    console.log("wow", event);
    if (this.state.selectedNurse != null && event == true) {
      this.state.dutyHours = "12:00";
      this.setState({
        isTrue: false,
        isTrue12: true
      })
      this.calcBillingAmount();
    }
    console.log("local12", this.state.dutyHours, this.state.isTrue);
  }



  calcBillingAmount = () => {
    let billingAmount = 0;
    let nurseCost = 0;

    if (this.state.dutyHours == "08:00") {
      nurseCost = this.state.selectedNurse.cost_eight_hours
    } else if (this.state.dutyHours == "12:00") {
      nurseCost = this.state.selectedNurse.cost_twelve_hours
    } else if (this.state.dutyHours == "") {
      nurseCost = this.state.selectedNurse.cost_eight_hours
    }

    if (this.state.edit === true && this.state.isTrue == true
      && this.state.dutyHours != "08:00" && this.state.dutyHours != "12:00") {
      nurseCost = this.state.selectedNurse.cost_eight_hours
    } else if (this.state.edit === true && this.state.isTrue12 == true
      && this.state.dutyHours != "08:00" && this.state.dutyHours != "12:00") {
      nurseCost = this.state.selectedNurse.cost_twelve_hours
    }
    console.log("antman", nurseCost);
    if (this.state.dealOption === "F") {
      if (parseFloat(Number(
        (this.state.bookingDetails.deal_amt.value *
          nurseCost) /
        100
      )).toFixed(3) >= 1 || this.state.bookingDetails.deal_amt.value == "") {
        billingAmount = nurseCost - (nurseCost * this.state.bookingDetails.deal_amt.value || 0) / 100;
        this.fieldreq = false
      }
      else {
        billingAmount = nurseCost - (nurseCost * this.state.bookingDetails.deal_amt.value || 0) / 100;
        this.fieldreq = true;
        this.state.bookingDetails.deal_amt.error = true;
      }
    } else {
      if (this.state.bookingDetails.deal_amt.value >= 1 || this.state.bookingDetails.deal_amt.value == "") {
        billingAmount =
          nurseCost - (this.state.bookingDetails.deal_amt.value || 0);
        this.fieldreq = false
      }
      else {
        billingAmount =
          nurseCost - (this.state.bookingDetails.deal_amt.value || 0);
        this.fieldreq = true;
        this.state.bookingDetails.deal_amt.error = true;
      }
    }
    billingAmount = billingAmount.toFixed(2);
    this.setState({ billingAmount, packageCost: nurseCost, billingAmountError: billingAmount < 10 });
  };

  changeDealOption = (data, dropType) => {
    console.log("record", data);
    if (dropType === "duty_radio") {
      this.setState(
        { dutyOption: data },
        () => this.state.selectedNurse && this.calcBillingAmount()
      );
    } else {
      this.setState(
        { dealOption: data },
        () => this.state.selectedNurse && this.calcBillingAmount()
      );
    }
  };



  getRangeData = (data) => {
    if (data.enddate === null) {
      this.setState({ deal_valid_from: data.startdate }, () =>
        this.compareDate()
      );
    }
    if (data.enddate == null) {
      this.state.deal_valid_to = ""
    }
    else {
      if (data.startdate < data.enddate) {
        this.setState(
          { deal_valid_from: data.startdate, deal_valid_to: data.enddate },
          () => this.compareDate()
        );
      } else {
        this.setState(
          { deal_valid_from: data.enddate, deal_valid_to: data.startdate },
          () => this.compareDate()
        );
      }
    }
  };

  services = () => {
    let services = [];
    for (let i = 0; i < this.state.serviceType.length; i++) {
      services.push(
        <Option value={this.state.serviceType[i].id}>
          {this.state.serviceType[i].serviceType}
        </Option>
      );
    }

    return services;
  };

  onSubmitData = () => {
    var serviceId = !this.state.edit && !this.state.serviceTypeAll;
    var data = [];
    if (!this.state.serviceTypeAll) {
      this.state.serviceType.map((val) => val.id > 1 && data.push(val.id));
    }

    if (this.state.edit === true) {
      var bookingDetails = {
        userId: 1,
        dealvendorId: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "12",
        // dealservicetypeId: this.state.serviceTypeAll
        //   ? this.state.servicetype
        //   : data,
        dealservicetypeId: [this.state.bookingDetails.package.value],
        dealtitle: this.state.bookingDetails.deal_title.value,
        dealvalidfrom: dateformat(this.state.deal_valid_from, "yyyy-mm-dd"),
        dealvalidto: dateformat(this.state.deal_valid_to, "yyyy-mm-dd"),
        dealoptions: this.state.dealOption === "M" ? "Amount" : "Percentage",
        // nurse_duty_hrs: this.state.dutyOption,
        // nurse_duty_hrs: this.state.dutyHours,
        // nurse_duty_hrs: this.props.edit_booking_data.nurse_duty_hrs ? this.props.edit_booking_data.nurse_duty_hrs : this.state.dutyHours,
        nurse_duty_hrs: this.state.dutyHours != "" ? this.state.dutyHours : this.props.edit_booking_data.nurse_duty_hrs,
        dealamount: this.state.bookingDetails.deal_amt.value,
        dealactive: this.state.dealActive === true ? 1 : 0,
        ipaddress: this.state.myIpAddress,
        activeflag: 1,
        createdby: 1,
        createdon: dateformat(new Date(), "yyyy-mm-dd"),
        modifiedby: 1,
        modifiedon: dateformat(new Date(), "yyyy-mm-dd"),
        // nurseWorkingHours: this.state.dutyHours
      };
    } else {
      var bookingDetails = {
        userId: 1,
        dealvendorId: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "12",
        // dealservicetypeId: this.state.serviceTypeAll
        //   ? this.state.servicetype
        //   : data,
        dealservicetypeId: [this.state.bookingDetails.package.value],
        dealtitle: this.state.bookingDetails.deal_title.value,
        dealvalidfrom: dateformat(this.state.deal_valid_from, "yyyy-mm-dd"),
        dealvalidto: dateformat(this.state.deal_valid_to, "yyyy-mm-dd"),
        dealoptions: this.state.dealOption === "M" ? "Amount" : "Percentage",
        // nurse_duty_hrs: this.state.dutyOption,
        nurse_duty_hrs: this.state.dutyHours == "" ? "08:00" : this.state.dutyHours,
        dealamount: this.state.bookingDetails.deal_amt.value,
        dealactive: this.state.dealActive === true ? 1 : 0,
        ipaddress: this.state.myIpAddress,
        activeflag: 1,
        createdby: 1,
        module_name: "Nurse",
        createdon: dateformat(new Date(), "yyyy-mm-dd"),
        modifiedby: 1,
        modifiedon: dateformat(new Date(), "yyyy-mm-dd"),
        // nurseWorkingHours: this.state.dutyHours
      };
    }

    if (this.state.edit === false) {
      console.log("qqqqq");
      this.bookingDetailsInsertApi(bookingDetails);
    } else {
      console.log("aaaaaa");
      this.bookingDetailsEditApi(bookingDetails);
    }
  };

  // Once form submitted successfully then form resets happens here
  resetFormValue = () => {
    localStorage.setItem("InsertDeal", 5);
    this.setState({
      edit: false,
      bookingDetails: {
        package: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        deal_title: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        deal_amt: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
      },
      deal_valid_from: getTomorrowDate(),
      deal_valid_to: getTomorrowDate(),
      dealOption: "F",
      dealActive: false,
      billingAmount: 0,
      selectedNurse: null,
      nurseCost: "08:00",
      packageCost: 0,
      billingAmountError: false,
    });
  };

  bookingDetailsInsertApi = (bookingDetails) => {
    Axios({
      method: "POST",
      url: apiurl + "Diet/insertDeals",
      data: {
        ...bookingDetails,
      },
    })
      .then((response) => {
        if (response.data.response[0].p_return_value != -1) {
          if (localStorage.nurse_language == 'ar') {
            this.handleopenSuccess("! تم إضافة العرض", true);
          } else {
            this.handleopenSuccess("Deal added successfully!", true);
          }
          localStorage.setItem("InsertDeal", response.data.response[0].p_return_value);

        } else if (response.data.response[0].p_return_value == -1) {
          if (localStorage.nurse_language == 'ar') {
            this.handleopenSuccess("تم حجز الصفقة بالفعل للتاريخ المحدد.", false);
          } else {
            this.handleopenSuccess("Deal already booked for selected date.", false);
          }

        } else {
          if (localStorage.nurse_language == 'ar') {
            this.handleopenSuccess("الصفقة المحجوزة فشلت", false);
          } else {
            this.handleopenSuccess("Deal Booked Failed", false);
          }
        }

        this.resetFormValue();
        this.getDealsList();
        this.setState({ afteredit: true });
      })
      .catch((error) => { });
  };

  bookingDetailsEditApi = (bookingDetails) => {
    Axios({
      method: "PUT",
      url: apiurl + "Diet/editDeals",
      data: {
        id: this.state.editData.id,
        ...bookingDetails,
      },
    })
      .then((response) => {
        debugger;
        const message = response.data.data[0].p_return_msg;
        const isArabic = localStorage.getItem("language") === "ar";
        let popupMessage;

        if (message === "This deal is booked, so can't edit it") {
          popupMessage = isArabic
            ? "تم حجز هذه الصفقة، لذا لا يمكن تعديلها"
            : message;
          this.handleopenSuccess(popupMessage, false);
        } else {
          this.resetFormValue();
          this.getDealsList();
          this.setState({ afteredit: true, activeKey: "1", edit: false });
          popupMessage = isArabic
            ? "تم تحديث الصفقة بنجاح!"
            : "Deal updated successfully!";
          this.handleopenSuccess(popupMessage, true);
          localStorage.setItem("InsertDeal", 1);
        }
      })
      .catch((error) => {
        console.error("Error editing deal:", error);
      });
  };

  getDealsList = () => {
    var data = {
      vendor_id: localStorage.getItem("nurse_vendor_id")
        ? localStorage.getItem("nurse_vendor_id")
        : "12",
      limit: 100,
      pageno: 1,
    };
    Axios({
      method: "POST",
      url: apiurl + "Diet/getsingle_deals",
      data: data,
    })
      .then((response) => {
        this.setState({
          dealsList: response.data.data[0].details,
        });
      })
      .catch((error) => {
        // alert(JSON.stringify(error));
      });
  };

  changedateFun = (data, name) => {
    this.setState({ [name]: data }, () => this.compareDate());
  };

  storeService = (data) => {
    if (data == 1) {
      this.setState({ serviceTypeAll: false });
    } else {
      this.setState({ servicetype: data, serviceTypeAll: true });
    }
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  }

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  }


  storeadSize = (event) => {
    console.log("ofthings", event)
  }




  handleChange = (event) => {
    this.setState({ isTrue: event.target.checked }); // Update the state when the checkbox changes
  }



  render() {
    const { TabPane } = Tabs;
    const { t } = this.props
    return (
      <div className="booking_createlist">
        <Grid container>
          <Grid item xs={12} md={7} style={{ order: (this.props.i18n.language == 'ar' ? 1 : 0) }}>


            <Calendar getDate={(data) => this.getRangeData(data)} t={t} i18n={this.props.i18n} />

          </Grid>
          <Grid item xs={12} md={5} style={{ paddingTop: '10px', order: (this.props.i18n.language == 'ar' ? 0 : 1) }}>
            <Grid container spacing={2} className="deal_container">
              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} `}>
                <div>
                  <Labelbox
                    type="select"
                    labelname={t('tbl_head_nurse')}
                    valuelabel={"serviceType"}
                    valuebind={"id"}
                    dropdown={this.state.serviceType}
                    changeData={(data) =>
                      this.changeDynamic(data, "package")
                    }
                    value={this.state.bookingDetails.package.value}
                    error={this.state.bookingDetails.package.error}
                    errmsg={this.state.bookingDetails.package.errmsg}
                  />
                </div>
              </Grid>
              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} `}>
                <Labelbox
                  type="text"
                  labelname={t('deal_title')}
                  valuelabel={"deal_title"}
                  changeData={(data) =>
                    this.changeDynamic(data, "deal_title")
                  }
                  value={this.state.bookingDetails.deal_title.value}
                  error={this.state.bookingDetails.deal_title.error}
                  errmsg={this.state.bookingDetails.deal_title.errmsg}
                />
              </Grid>

              <Grid className={`${this.props.i18n.language == 'ar' ? "order_3" : ""} deal_date_picker_div`} item xs={6} md={6} style={{ paddingBottom: '25px' }}>
                <label className="labeltxt"> {t('tbl_head_from')}  </label>
                <input
                  type="text"
                  className="html__input"
                  value={this.state.deal_valid_from != "" ? moment(this.state.deal_valid_from).format("DD-MMM-YY") : ""}
                ></input>

              </Grid>

              <Grid className={`${this.props.i18n.language == 'ar' ? "order_2" : ""} deal_date_picker_div`} item xs={6} md={6} style={{ paddingBottom: '25px' }}>
                <label className="labeltxt"> {t('tbl_head_to')}  </label>
                <input
                  type="text"
                  className="html__input"
                  value={this.state.deal_valid_to != "" ? moment(this.state.deal_valid_to).format("DD-MMM-YY") : ""}
                ></input>

                <div className="validation__error--minus err_clrrr">
                  {this.state.dateError &&
                    t('to_date_should_be_greater_than_from_date')}
                </div>
              </Grid>




              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_5" : ""} `}>
                {/* <Labelbox
                  type="text"
                  labelname="Working Hours"
                  valuelabel={"Working Hours"}
                  changeData={(data) =>
                    this.changeDynamic(data, "working_nurse")
                  }
                  value={this.state.bookingDetails.deal_title.value}
                  error={this.state.bookingDetails.deal_title.error}
                  errmsg={this.state.bookingDetails.deal_title.errmsg}
                /> */}
                <div className={`${this.props.i18n.language == 'ar' ? "ar_direction" : ""} d-flex justifyContentStart alignItemsCenter`}>
                  <label style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "", marginTop: this.props.i18n.language == "ar" ? "7px" : "6px" }} className={`${this.props.i18n.language == 'ar' ? "ar_checkLabel" : ""} labeltxt`}>{t("8_hours")}</label>
                  <Checkbox
                    className="active_addpackagee"
                    // checked={this.state.adsize == checkingsize.id ? true : false}
                    // value={this.state.edit ? this.state.adsize : checkingsize.ad_size}
                    checked={this.state.isTrue}
                    onChange={e => {
                      console.log(e.target.checked);
                      this.dynamiChange(e.target.checked)
                      // this.setState({ isTrue: e.target.checked });
                    }}
                  // onChange={this.handleChange}
                  ></Checkbox>
                </div>



              </Grid>
              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_4" : ""} `}>
                {/* <Labelbox
                  type="text"
                  labelname="Working Hours"
                  valuelabel={"Working Hours"}
                  changeData={(data) =>
                    this.changeDynamic(data, "working_nurse")
                  }
                  value={this.state.bookingDetails.deal_title.value}
                  error={this.state.bookingDetails.deal_title.error}
                  errmsg={this.state.bookingDetails.deal_title.errmsg}
                /> */}
                <div className={`${this.props.i18n.language == 'ar' ? "ar_direction" : ""} d-flex justifyContentStart alignItemsCenter`}>
                  <label style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "", marginTop: this.props.i18n.language == "ar" ? "7px" : "6px" }} className={`${this.props.i18n.language == 'ar' ? "ar_checkLabel" : ""} labeltxt`}>{t("12_hours")}</label>
                  <Checkbox
                    // checked={this.state.adsize == checkingsize.id ? true : false}
                    // value={this.state.edit ? this.state.adsize : checkingsize.ad_size}
                    // onChange={() => this.storeadSize(checkingsize.id)}
                    className="active_addpackagee"
                    checked={this.state.isTrue12}
                    onChange={e => {
                      console.log(e.target.checked);
                      this.dynamiChange2(e.target.checked)
                      // this.setState({ isTrue: e.target.checked });
                    }}
                  ></Checkbox>
                </div>



              </Grid>


              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_7" : ""} `}>
                <div className="radio_buttons">
                  <Labelbox
                    labelname={t('deal_options')}
                    type="radio"
                    checked={this.state.dealOption}
                    changeGender={(data) => this.changeDealOption(data)}
                    t={t}
                    i18n={this.props.i18n}
                  />
                </div>
              </Grid>

              <Grid item xs={6} md={6} className={`${this.props.i18n.language == 'ar' ? "order_6" : ""} `}>
                <div className="deal_radiopercent" style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "" }}>
                  <div className="deal_kwdalign" style={{ fontSize: '14px' }}>
                    <Labelbox
                      type="number"
                      valuelabel={"deal_amt"}
                      // changeData={(data) => { this.changeDynamic(data, "deal_amt"); }
                      changeData={(data) => { this.validateInput(data, "deal_amt"); }
                      }
                      value={this.state.bookingDetails.deal_amt.value}
                      error={this.state.bookingDetails.deal_amt.error}
                      errmsg={this.state.bookingDetails.deal_amt.errmsg}
                      style={{ marginTop: "2rem", order: this.props.i18n.language == 'ar' ? 1 : 0 }}
                    />
                    {" "}
                    <span className="deal_kwd">{this.state.dealOption == ("F") ? ("%") : (<span style={{ fontSize: '10px', marginTop: localStorage.nurse_language == 'ar' ? "-6px" : "" }}>{t("kwd")}</span>)}</span>
                  </div>
                </div>

                {this.fieldreq && !this.state.bookingDetails.deal_amt.errmsg &&
                  <div className="Errormsgs">
                    <span>{t('deal_amount_greater_than_1_kwd')} </span>
                  </div>
                }

              </Grid>


              <Grid item xs={12} md={12} className={`${this.props.i18n.language == 'ar' ? "order_8" : ""} `}>

                <div className="checkbox_details_deals" style={
                  this.props.i18n.language === "ar"
                    ? { display: "flex", justifyContent: "right", direction: "rtl" }
                    : {}
                }>
                  <div>
                    <Checkbox
                      style={{ paddingRight: this.props.i18n.language == 'ar' ? "0px" : "", marginRight: this.props.i18n.language == 'ar' ? "-3px" : "" }}
                      className="active_addpackagee"
                      checked={this.state.dealActive}
                      value="secondary"
                      onChange={e => {
                        this.activeChange(e.target.checked)
                      }}
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div style={{ fontSize: '16px', color: 'black' }}>{t("active")}</div>
                </div>

              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{
                  padding: "0px",
                  margin: '5px'
                }}
                className={`${this.props.i18n.language == 'ar' ? "order_9" : ""} `}
              >
                <Grid
                  style={{ display: 'flex', justifyContent: 'space-between', textAlign: this.props.i18n.language == 'ar' ? "right" : "" }}
                  container
                  className={
                    this.state.billingAmount >= 10
                      ? "calc-cost-green-div"
                      : "calc-cost-red-div"
                  }
                >
                  <Grid item style={{ fontSize: "16px" }} className={`${this.props.i18n.language == 'ar' ? "order_2 ar_packageRate" : ""} `}>
                    <div style={{ color: "black " }}>
                      {t("package_rate_with_kwd") == "Package Rate (KWD)" ? (
                        <span>
                          Package Rate
                          <span style={{ fontSize: "10px" }}>{" "}({localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"})</span>
                        </span>
                      ) : (
                        <span>{t("package_rate_with_kwd")}</span>
                      )}
                    </div>
                    <div className="amt-alig">
                      {this.state.packageCost ? parseFloat(this.state.packageCost).toFixed(3) : "0.000"}
                    </div>
                  </Grid>
                  <Grid item style={{ fontSize: "16px", marginLeft: "2.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} `}>
                    <div style={{ color: "black " }}>
                      {/* {t("deal_amt_with_kwd")} */}
                      {t("deal_amt_with_kwd") === "Deal Amt (KWD)" ? (
                        <span>
                          Deal Amt
                          <span style={{ fontSize: "10px" }}>{" "}({localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"})</span>
                        </span>
                      ) : (
                        <span>{t("deal_amt_with_kwd")}</span>
                      )}
                    </div>
                    <div className="amt-alig">
                      {this.state.dealOption == "F"
                        ? this.state.bookingDetails?.deal_amt?.value
                          ? parseFloat(Number(
                            (this.state.bookingDetails.deal_amt.value *
                              this.state.packageCost) /
                            100
                          )).toFixed(3)
                          : "0.000"
                        : this.state.bookingDetails?.deal_amt?.value
                          ? parseFloat(Number(
                            this.state.bookingDetails.deal_amt.value
                          )).toFixed(3)
                          : "0.000"}{" "}
                    </div>
                  </Grid>
                  <Grid item style={{ fontSize: "16px", marginLeft: "2.5%" }} className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} `}>
                    <div style={{ color: "black " }}>
                      {/* {t("billing_amt_with_kwd")} */}
                      {t("billing_amt_with_kwd") === "Billing Amt (KWD)" ? (
                        <span>
                          Billing Amt
                          <span style={{ fontSize: "10px" }}>{" "}({localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"})</span>
                        </span>
                      ) : (
                        <span>{t("billing_amt_with_kwd")}</span>
                      )}{" "}
                    </div>
                    <div className="amt-alig">
                      {this.state.billingAmount
                        ? parseFloat(this.state.billingAmount).toFixed(3)
                        : "0.000"}
                    </div>
                  </Grid>


                </Grid>
                {this.state.billingAmountError && (
                  <div className="note-text" style={{ textAlign: (this.props.i18n.language == 'ar' ? "right" : "") }}>
                    {t('billingAmountIsInsufficient')}
                  </div>
                )}
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                style={{ display: "flex", justifyContent: (this.props.i18n.language == 'ar' ? "left" : "flex-end"), paddingTop: '20px' }}
                className={`${this.props.i18n.language == 'ar' ? "order_10" : ""} `}
              >
                <div className="Deal_activecheck">
                  <div
                    className="createbutton-container"
                    style={{ margin: "0px" }}
                  >
                    <Button
                      className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} create_cancel`}
                      onClick={this.resetFormValue}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      className="media_save hand-id"
                      onClick={this.checkValidation}
                      style={{ marginLeft: (this.props.i18n.language == 'ar' ? "20px" : "") }}
                    >
                      {this.state.edit === true ? t("update") : t("add")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShowNotification visible={this.state.visible} successmsg={this.state.successmsg} issuccess={this.state.issuccess} handleCloseSuccess={this.handleCloseSuccess} t={t} i18n={this.props.i18n}>
          <Calendar />
        </ShowNotification>

      </div>
    );
  }
}
