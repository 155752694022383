import React, { Component } from "react";
import Nursepage from "./Nurse_dash_page";
import Paper from "@material-ui/core/Paper";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import './Nurse_dash_page.css';
import i18n from "../../i18n/i18n";
import { withTranslation } from 'react-i18next';
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import addPlus from "../../Images/addOrange.png";
import Logo from "../../Images/Logos.png";
import PaymentLink from "../../Images/PaymentLink.png";
import Labelbox from "../../helpers/labelbox/labelbox";
import Button from "@material-ui/core/Button";
import axios from 'axios';  // Import axios for API call
import { apiurl, kneturl, paymentsuccessurl } from "../../App";
import { color } from "@amcharts/amcharts5";
import ValidationLibrary from "../../helpers/validationfunction";
import ShowNotification from "../ShowNotification/ShowNotification";

class Nurse_dash_master extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDateRange: false,
      insertOpen: false,
      reload: false,
      nameEmail: false,
      patientId: '',
      ccode: '',
      visible: false,
      reloadData: false,
      selectionType: [
        { name: "Male", id: 1 },
        { name: "Female", id: 2 }
      ],
      walkInDetails: {
        name: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        mobile_number: {
          value: "",
          validation: [{ name: "required" }, { name: "mobile" }],
          error: null,
          errmsg: null,
        },
        age: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        gender: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        E_mail: {
          value: "",
          validation: [{ name: "email" }],
          error: null,
          errmsg: null,
        },
      },
    };
  }

  getMobileNo = (data) => {
    if (data.length === 8 || data.length === 10) {
      var body = {
        phoneno: data
      };
      axios.post(apiurl + "Sports/memberdetailsWalkIn", body)
        .then((response) => {
          if (response.data.msg === "Success" && response.data.data.length > 0) {
            const memberData = response.data.data[0];
            this.setState({ ccode: memberData.cc_code });
            if (memberData.name || memberData.email) {
              debugger
              this.handleChange(memberData.name, "name")
              this.handleChange(memberData.email, "E_mail")
              this.handleChange(memberData.age, "age")   
              this.handleChange(memberData.gender == "Male" ? "1" : "Female", "gender")
              this.setState({
                nameEmail: true,
                patientId: memberData.patient_id
              });
            }
            else {
              this.setState({
                nameEmail: false,
                patientId: 0
              });
            }
          } else {
            this.setState({
              nameEmail: false,
              patientId: 0
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({
        nameEmail: false,
        patientId: 0,
        walkInDetails: {
          ...this.state.walkInDetails,
          name: { ...this.state.walkInDetails.name, value: "" },
          E_mail: { ...this.state.walkInDetails.E_mail, value: "" },
        },
      });
    }
  };

  mobileGetDetails = (data) => {
    if (data.length === 8 || data.length === 10) {
      this.getMobileNo(data)
    } else if ((data.length === 9 || data.length === 7 || data.length === 0) && this.state.patientId != "") {
      this.setState({
        nameEmail: false,
        patientId: 0,
        walkInDetails: {
          ...this.state.walkInDetails,
          name: { ...this.state.walkInDetails.name, value: "" },
          E_mail: { ...this.state.walkInDetails.E_mail, value: "" },
        },
      });
    }
  }

  handleChange = (data, key) => {
    debugger
    let { walkInDetails } = this.state;

    let errorcheck = ValidationLibrary.checkValidation(
      data,
      walkInDetails[key].validation
    );

    if (key === "name" || key === "E_mail" || key === "mobile_number" || key === "mobile_number" ) {
      walkInDetails[key].value = data;
      walkInDetails[key].error = !errorcheck.state;
      walkInDetails[key].errmsg = errorcheck.msg;
      this.setState({ walkInDetails });
    }
    else if(key === "gender"){
      walkInDetails[key].value = data == "1" ? "Male" : "Female";
      walkInDetails[key].error = !errorcheck.state;
      walkInDetails[key].errmsg = errorcheck.msg;   
      this.setState({ walkInDetails });
    }
    else if (key === "age") {
      
      if (data > "0") {
        walkInDetails[key].value = data;
        walkInDetails[key].error = !errorcheck.state;
        walkInDetails[key].errmsg = errorcheck.msg;
        this.setState({ walkInDetails });
      }
      else {
        walkInDetails[key].value = "";
        walkInDetails[key].error = true;
        walkInDetails[key].errmsg = "Field required";
        this.setState({ walkInDetails });
      }
    }
    else {
      walkInDetails[key].value = '';
      walkInDetails[key].error = !errorcheck.state;
      walkInDetails[key].errmsg = errorcheck.msg;
      walkInDetails[key].validation = [{ name: "required" }];
    }
  };

  insertModalOpen = () => {
    this.setState({
      insertOpen: true,
      walkInDetails: {
        ...this.state.walkInDetails,
        name: { ...this.state.walkInDetails.name, value: "" },
        E_mail: { ...this.state.walkInDetails.E_mail, value: "" },
        mobile_number: { ...this.state.walkInDetails.mobile_number, value: "" },
        mobile_number: { ...this.state.walkInDetails.mobile_number, validation: [{ name: "required" }] },

      },
    });
  };

  closemodal = () => {
    this.setState({
      insertOpen: false,
      nameEmail: false,
      patientId: 0,
      walkInDetails: {
        name: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        mobile_number: {
          value: "",
          validation: [{ name: "required" }, { name: "mobile" }],
          error: null,
          errmsg: null,
        },
        age: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        gender: {
          value: "",
          validation: [{ name: "required" }],
          error: null,
          errmsg: null,
        },
        E_mail: {
          value: "",
          validation: [{ name: "email" }],
          error: null,
          errmsg: null,
        },
      },
    });
  };

  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false, insertOpen: false, imageChanged: false })
  };

  handleSubmit = () => {
    debugger
    const { walkInDetails } = this.state;
    let formIsValid = true;
    for (let key in walkInDetails) {
      let errorcheck = ValidationLibrary.checkValidation(
        walkInDetails[key].value,
        walkInDetails[key].validation
      );

      walkInDetails[key].error = !errorcheck.state;
      walkInDetails[key].errmsg = errorcheck.msg;

      if (!errorcheck.state) {
        formIsValid = false;
      }
    }

    this.setState({ walkInDetails });

    if (formIsValid) {
      let walkInDetailsValue = this.state.walkInDetails;
      let data = {
        patientId: this.state.patientId ? this.state.patientId : 0,
        Name: walkInDetailsValue.name.value,
        Age: walkInDetailsValue.age.value,
        Gender: walkInDetailsValue.gender.value,
        emailId: walkInDetailsValue.E_mail.value,
        mobileNo: walkInDetailsValue.mobile_number.value,
        vendorId: localStorage.getItem('nurse_vendor_id'),
        ccode: this.state.ccode == "" ? walkInDetailsValue.mobile_number.value.length == 10 ? "91" : "965" : this.state.ccode,
        appointment_type: this.state.patientId ? 7 : 8,
        payment_status: 'P'
      }

      if (walkInDetailsValue.mobile_number.value.length === 8 || walkInDetailsValue.mobile_number.value.length === 10) {
        axios({
          method: "POST",
          url: apiurl + "nurse/sendWhatsappBookingLink",
          data: data
        })
          .then((response) => {
            this.handleopenSuccess(
              < div >
                <img width={35} src={Logo} />
                <span style={{ margin: "5px 5px 0px 5px" }}>Payment Link</span>
                <img width={25} src={PaymentLink} />
                <span style={{ margin: "5px 5px 0px 5px" }}>sent successfully!</span>
              </div >, true);
            this.setState({
              walkInDetails: {
                ...this.state.walkInDetails,
                name: { ...this.state.walkInDetails.name, value: "" },
                E_mail: { ...this.state.walkInDetails.E_mail, value: "" },
                mobile_number: { ...this.state.walkInDetails.mobile_number, value: "" },
              },
              nameEmail: false,
              reloadData: true

            })
          })
          .catch((error) => {
            console.log(error);
          });
      }

    }
  };

  test = (data) => {
    debugger
    this.setState({
      reloadData: data,
    });
  }
  render() {
    const { t } = this.props;
    return (
      <div>
        <Paper>
          <div className="title_dashboard" style={{ direction: (this.props.i18n.language === 'ar' ? 'rtl' : '') }}>
            <div className="row m-0" style={{ width: "100%" }}>
              <div className="col-4">
                <div className="title_header">{t('nurse_dashboard_heading')}</div>
              </div>
              <div className="col-5">
              </div>
              <div className="col-3" style={{ textAlign: 'end' }}>
                <img src={addPlus} style={{ cursor: "pointer" }} alt="" width={"32px"} onClick={this.insertModalOpen} />
              </div>
            </div>
          </div>
          <Nursepage ref="nurseDashboardPageRef" pageReload={this.state.reloadData} t={t} i18n={this.props.i18n} reloadfn={this.test} />
        </Paper>
        <Modalcomp
          className="manage_add_div"
          clrchange="text_color"
          visible={this.state.insertOpen}
          title={t("WALKINBOOKING")}
          closemodal={(e) => this.closemodal(e)}
          xswidth={"xs"}
          t={t}
          i18n={this.props.i18n}
          reload={this.state.reload}
        >
          <div className="walkIn">
            <div>
              <Labelbox
                type="number"
                maxlength={10}
                labelname={<span>{t("Mobile_number")}<span className="Req"> *</span></span>}
                changeData={(data) => { this.handleChange(data, "mobile_number"); this.mobileGetDetails(data); }}
                value={this.state.walkInDetails.mobile_number.value}
                error={this.state.walkInDetails.mobile_number.error}
                errmsg={this.state.walkInDetails.mobile_number.errmsg}
              />
            </div>
            <div>
              <Labelbox
                type="text"
                labelname={<span>{t("name")}<span className="Req"> *</span></span>}
                changeData={(data) => this.handleChange(data, "name")}
                disabled={this.state.nameEmail}
                value={this.state.walkInDetails.name.value}
                error={this.state.walkInDetails.name.error}
                errmsg={this.state.walkInDetails.name.errmsg}
              />
            </div>
            <div>
              <Labelbox
                type="number"
                labelname={<span>{t("age")}<span className="Req"> *</span></span>}
                changeData={(data) => this.handleChange(data, "age")}
                disabled={this.state.nameEmail}
                maxlength={3}
                value={this.state.walkInDetails.age.value}
                error={this.state.walkInDetails.age.error}
                errmsg={this.state.walkInDetails.age.errmsg}
              />
            </div>
            <div className="training_adjusts">
              <Labelbox
                labelname={<span>{t("gender")}<span className="Req"> *</span></span>}
                type="select"
                dropdown={this.state.selectionType}
                read_only={this.state.nameEmail}
                valuelabel={"name"}
                valuebind={"id"}
                changeData={(data) => this.handleChange(data, "gender")}
                value={this.state.walkInDetails.gender.value}
                error={this.state.walkInDetails.gender.error}
                errmsg={this.state.walkInDetails.gender.errmsg}
              />
            </div>
            <div>
              <Labelbox
                type="text"
                labelname={<span>{t("email_id")}</span>}
                changeData={(data) => this.handleChange(data, "E_mail")}
                disabled={this.state.nameEmail}
                value={this.state.walkInDetails.E_mail.value}
                error={this.state.walkInDetails.E_mail.error}
                errmsg={this.state.walkInDetails.E_mail.errmsg}
              />
            </div>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <Button
                style={{ background: "#510F30", borderRadius: '25px', fontSize: 'smaller' }}
                className="save_button"
                variant="contained"
                color="primary"
                onClick={this.handleSubmit}
              >
                {t('sendBookingLink')}
              </Button>
            </div>
          </div>
        </Modalcomp>

        <ShowNotification
          visible={this.state.visible}
          successmsg={this.state.successmsg}
          issuccess={this.state.issuccess}
          handleCloseSuccess={() => { this.handleCloseSuccess(); }}
          t={t}
          i18n={this.props.i18n}
        ></ShowNotification>
      </div>
    );
  }
}
export default withTranslation()(Nurse_dash_master);
