import React, { Component } from "react";
import "antd/dist/antd.css";
import { Input, Tooltip, Button } from "antd";
import { apiurl } from "../../App";
import Axios from "axios";
import dateFormat from "dateformat";

import back from "../../Images/rightarrow.svg";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import ReactToPrint from "react-to-print";
import ReactExport from "react-data-export";
import PrintData from "./printdata";
import ReactSVG from "react-svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DateRangeSelect from "../../helpers/DateRange/DateRange";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import ProfileView from '../ProfileView/ProfileView';
import NurseView from "../NurseView/Nurse_view";
import ArrowLeft from "@material-ui/icons/ChevronLeftSharp"
import RightArrowOnly from "../../Images/right-arrow-only.png";
import PDF from "../CommonPDF/PDF";
import downloadExcel from "../Common Excel/Excel";
import { notification } from "antd";
import { formatMoney } from "../../App";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const headRows = [
  { id: "sno", label: "S.No", typeDt: 'sno' },
  { id: "customer", label: "Nursename", typeDt: 'str' },
  { id: "workingHours", label: "Duty Hours " },
  { id: "startDate", label: "From ", type: "date", typeDt: 'date' },
  { id: "endDate", label: "To", type: "date", typeDt: 'date' },
  { id: "no_of_days", label: "No.of Days" },
  { id: "totalcost", label: "Cost", type: "amt", typeDt: 'amt' },
];
class NurseCustomerHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tableData: [],
      search: null,
      props_loading: true,
      fromDate: dateFormat(new Date(), "yyyy-mm-dd"),
      toDate: dateFormat(new Date(), "yyyy-mm-dd"),
      openview: false,
      viewdata: []
    };
  }

  componentDidMount() {
    this.getTableData();

    this.intervalId = setInterval(() => {
      this.getTableData();
    }, 120000);

  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  // data has empty this alert msg showed print pdf excel
  Notification = () => {
    notification.warning({
      message: "No data found",
      placement: "topRight",
    });
  };

  // get table data
  getTableData = () => {
    var self = this;
    Axios({
      method: "POST", //get api
      url: apiurl + "Nurse/getvendornurseCustomerhistory",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id")
          ? localStorage.getItem("nurse_vendor_id")
          : "5",
        CustomerId: this.props.customerId, // prop coming from index.js
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        period: "Day",
      },
    })
      .then((response) => {
        var tableData = [];
        response.data.data.map((val, index) => {
          tableData.push({
            customer: val.Nursename,
            workingHours: val.working_hours,
            startDate: dateFormat(val.from_date, "dd-mmm-yy"),
            endDate: dateFormat(val.to_date, "dd-mmm-yy"),
            designedDuty:
              val.Dutiesofnurse == ""
                ? ""
                : JSON.parse(val.Dutiesofnurse)
                  .map((val) => val.duties)
                  .toString(),
            // designedDuty: "",
            // designedDutyTable:
            //   val.Dutiesofnurse == "" ? [] : JSON.parse(val.Dutiesofnurse),
            no_of_days: val.no_of_days,
            totalcost: parseFloat(val.total_charge).toFixed(3),
            id: index,
            customerName: val.Customername,
            nurseId: val.nurseId,
          });

          val.Dutiesofnurse = val.Dutiesofnurse == ""
            ? ""
            : JSON.parse(val.Dutiesofnurse)
        });
        self.setState({
          tableData: tableData,
          props_loading: false,
          totalData: response.data.data,
        });
        self.setState({});
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
      });
  };

  getRangeDate = (item) => {
    console.log(item, "checking Date");
    this.setState(
      {
        fromDate: dateFormat(item[0].startDate, "yyyy-mm-dd"),
        toDate: dateFormat(item[0].endDate, "yyyy-mm-dd"),
      },
      () => this.getTableData()
    );
  };

  openmodal = () => {
    this.setState({ open: true });
  };
  onclosemodal = () => {
    this.setState({ open: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };


  generatepdf = () => {
    const doc = new jsPDF("a3");
    var bodydata = [];

    this.state.tableData.map((data, index) => {
      bodydata.push([
        // index + 1,
        // data.customer,
        // data.workingHours,
        // data.startDate,
        // data.endDate,
        // // data.designedDuty,
        // data.no_of_days,
        // data.totalcost,

        { content: index + 1, styles: { halign: 'center' } },
        { content: data.customer, styles: { halign: 'center' } }, // Left align for Member column
        { content: data.workingHours, styles: { halign: 'center' } }, // Left align for Member column
        { content: data.startDate, styles: { halign: 'center' } },

        { content: data.endDate, styles: { halign: 'center' } }, // Left align for Member column
        { content: data.no_of_days, styles: { halign: 'center' } },

        { content: data.totalcost, styles: { halign: 'center' } },



      ]);
    });
    doc.autoTable({
      beforePageContent: function (data) {
        doc.text("Nurse Customer History", 15, 23); // 15,13 for css
      },
      margin: { top: 30 },
      showHead: "everyPage",
      theme: "grid",
      head: [
        [
          "S.No",
          "Nurse",
          "Duty Hours",
          "From",
          "To",
          // "Designed Duty",
          "No. of Days",
          "Fee (KWD)",
        ],
      ],
      body: bodydata,
    });

    doc.save("Nurse_Customer_History.pdf");
  };

  modelopen = (data, id) => {
    if (data === "view") {
      this.setState({
        openview: true,
        viewdata: this.state.totalData[id],
      });
    }
  };

  closemodal = () => {
    this.setState({ openview: false });
  };

  render() {
    const { Search } = Input;
    const { t } = this.props;

    const heading = ((localStorage.nurse_language == 'ar') ? [
      { id: "", label: t("tbl_head_remarks") },
      { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
      { id: "noofdays", label: t("no_of_days") },
      { id: "endDate", label: t("tbl_head_to") },
      { id: "startDate", label: t("tbl_head_from") },
      { id: "workingHours", label: t("tbl_head_duty_hours") },
      { id: "customer", label: t("tbl_head_nurse") },
      { id: "", label: t("tbl_head_s_no") },
    ] : [
      { id: "", label: t("tbl_head_s_no") },
      { id: "customer", label: t("tbl_head_nurse") },
      { id: "workingHours", label: t("tbl_head_duty_hours") },
      { id: "startDate", label: t("tbl_head_from") },
      { id: "endDate", label: t("tbl_head_to") },
      { id: "noofdays", label: t("no_of_days") },
      { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
      { id: "", label: t("tbl_head_remarks") }
    ])
    const searchData = [];
    this.state.tableData.filter((data, index) => {
      if (this.state.search === null)
        searchData.push({
          customer: data.customer,
          workingHours: data.workingHours,
          startDate: data.startDate,
          endDate: data.endDate,
          /* designedDutyTable:
             data.designedDutyTable.map((val) => val.duties).toString().length >
             25 ? (
               <Tooltip
                 placement="top"
                 title={data.designedDutyTable
                   .map((val) => val.duties)
                   .toString()}
               >
                 <span>
                   {data.designedDutyTable
                     .map((val) => val.duties)
                     .toString()
                     .substring(0, 25) + "..."}
                 </span>
               </Tooltip>
             ) : (
               data.designedDutyTable.map((val) => val.duties).toString()
             ),*/
          no_of_days: data.no_of_days,
          totalcost:formatMoney (data.totalcost),
          id: data.id,
        });
      else if (
        (data.customer !== null &&
          data.customer
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.workingHours !== null &&
          data.workingHours
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.startDate !== null &&
          data.startDate.toString().includes(this.state.search.toString())) ||
        (data.endDate !== null &&
          data.endDate.toString().includes(this.state.search.toString())) ||
        /*  (data.designedDuty != null &&
            data.designedDuty
              .toLowerCase()
              .includes(this.state.search.toLowerCase())) ||*/
        (data.no_of_days != null &&
          data.no_of_days.toString().includes(this.state.search.toString())) ||
        (data.totalcost != null &&
          data.totalcost.toLowerCase().includes(this.state.search.toLowerCase()))
      ) {
        searchData.push({
          customer: data.customer,
          workingHours: data.workingHours,
          startDate: data.startDate,
          endDate: data.endDate,
          /* designedDutyTable:
             data.designedDutyTable.map((val) => val.duties).toString().length >
             20 ? (
               <Tooltip
                 placement="top"
                 title={data.designedDutyTable
                   .map((val) => val.duties)
                   .toString()}
               >
                 <span>
                   {data.designedDutyTable
                     .map((val) => val.duties)
                     .toString()
                     .substring(0, 20) + "..."}
                 </span>
               </Tooltip>
             ) : (
               data.designedDutyTable.map((val) => val.duties).toString()
             ),*/
          no_of_days: data.no_of_days,
          totalcost: data.totalcost,
          id: data.id,
        });
      }
    });

    // EXCEL FUNCTION
    var multiDataSetbody = [];
    this.state.tableData.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.customer },
          { value: xldata.workingHours },
          { value: xldata.startDate },
          { value: xldata.endDate },
          // { value: xldata.designedDuty },
          { value: xldata.no_of_days },
          { value: xldata.totalcost },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.customer,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },

          {
            value: xldata.workingHours,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.startDate,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.endDate,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          /*      {
                  value: xldata.designedDuty,
                  style: {
                    fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
                  },
                },*/
          {
            value: xldata.no_of_days,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.totalcost,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
        ]);
      }
    });
    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Nurse",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },

          {
            title: "Duty Hours",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "From",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "To",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          /* {
             title: "Designed Duty",
             width: { wpx: 100 },
             style: {
               fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
             },
           },*/
          {
            title: "No. of Days",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
          {
            title: "Fee (KWD)",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "86b149" } },
            },
          },
        ],
        data: multiDataSetbody,
      },
    ];

    return (
      <div>
        <div className="title_dashboard">
          <div className="title_header" style={{ direction: localStorage.nurse_language == 'ar' ? "rtl" : "ltr" }}>
            <span
              style={{
                cursor: "pointer",
                display: "inline-block",
                marginRight: "15px",
              }}
              onClick={() => this.props.backToFirstTable()}
            >
              {/* <ReactSVG src={back} /> */}
              {localStorage.nurse_language == 'ar' ?
                <img src={RightArrowOnly} width={18} height={18} alt="arrow" style={{ cursor: "pointer" }} />
                :
                <ArrowLeft className='left_arrow_dashboard'> </ArrowLeft>
              }
            </span>
            <span className={`${localStorage.nurse_language == 'ar' ? "ml-4" : ""} mr-4`}>{t("customer_history")}</span>
            <span
              style={{
                color: "#510f30",
                fontSize: "20px",
                marginRight: localStorage.nurse_language == 'ar' ? "-12px" : "3px",
                marginLeft: localStorage.nurse_language == 'ar' ? "3px" : "-12px",
              }}
            >
              -
            </span>
            <span style={{ textTransform: 'uppercase' }}>{this.props.customerName && this.props.customerName}</span>
          </div>
          <div
            style={{ fontSize: "14px", display: "flex", alignItems: "center" }}
          >
            {/* <DateRangeSelect dynalign={"dynalign"} rangeDate={(item) => this.getRangeDate(item)} /> */}
            <Search
              placeholder={t('search')}
              onChange={(e) => this.searchChange(e)}
              style={{ width: 150, direction: this.props.i18n.language == "ar" ? "rtl" : "", margin: "0px 10px 0px 0px"  }}
              className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} mr-2 ml-2`}
            />
            <div className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} icon_head`} style={{ direction: this.props.i18n.language == 'ar' ? "rtl" : "" }}>
              {/* <ReactSVG
                src={pdf}
                style={{ marginRight: "15px", marginLeft: "15px" }}
                onClick={this.generatepdf}
              /> */}
              <PDF
                weekMonthYearData={this.state.tableData}
                headRows={headRows}
                filename={"Nursecustomerhistory Bookings"}
                headingTitle={'Nurse customer history'}
                lang={this.props.i18n.language}
              />

              <img
                onClick={() => {
                  downloadExcel('Nurse Customer History', this.state.tableData, headRows, '', [])
                }}
                src={excel}
                style={{ cursor: 'pointer' }}
              />
            
              {this.state.tableData.length === 0 ? (
                <ReactSVG src={print} onClick={this.Notification} />
              ) : (
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
              )}

            </div>
            <div style={{ display: "none" }}>
              <PrintData
                printTableData={this.state.tableData}
                DetailedHistory={true}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </div>
        </div>
        <div className="customer_history_sub_table_div">
          <Tablecomponent
            heading={heading}
            rowdata={searchData}
            deleteopen={this.deleteopen}
            modelopen={(e, id) => this.modelopen(e, id)}
            props_loading={false}
            // actionclose={"close"}
            // VisibilityIcon={"close"}
            EditIcon={"close"}
            LocationOnIcon={"close"}
            HistoryIcon={"close"}
            DeleteIcon={"close"}
            t={t}
            i18n={this.props.i18n}
          />
        </div>

        <NurseView
          open={this.state.openview}
          ViewData={this.state.viewdata}
          onClose={this.closemodal}
          is_from_manage_nuser="false"
          t={t}
          i18n={this.props.i18n}
        />

        {/* <ProfileView
            open={this.state.openview}
            viewdata={this.state.viewdata}
            onClose={this.closemodal}
            isnursehistory={false}
          /> */}
      </div>
    );
  }
}
export default NurseCustomerHistoryTable;