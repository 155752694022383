import React, { Component } from "react";
import { Paper, Card, Divider } from "@material-ui/core";
import "./Nurse_dash_page.css";
import { NavLink } from "react-router-dom";
import Nursedetails from "./Nurse_dash_details";
import { apiurl } from "../../App";
import Axios from "axios";
import dateFormat from "dateformat";
import { formatMoney } from "../../App";
import logos from "../../Images/Logos.png";
import walkin from "../../Images/walkin.svg";

var moment = require("moment");

class Nurse_dash_page extends Component {
  state = {
    nursehired: 0,
    completedbooking: 0,
    ongoingbooking: 0,
    bookingyettostart: 0,
    canceledbooking: 0,
    revenue: 0,
    TableData: [],
    ViewData: "",
    CostofMonth: "",
    Duties: [],
    props_loading: true,
    startDate: "",
    endDate: ""
  };

  componentWillMount() {
    const data = {
      startDate: new Date(),
      endDate: new Date(),
    }
    this.AppointmentGetData(data);

    this.intervalId = setInterval(() => {
      const data = {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.AppointmentGetData(data);
    }, 120000);


  }

  componentDidUpdate() {
    if (this.props.pageReload) {
      const data = {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.AppointmentGetData(data);
      this.props.reloadfn(false);
    }
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted to avoid memory leaks
    clearInterval(this.intervalId);
  }

  AppointmentGetData = (data) => {
    var self = this;
    let getDate = new Date();
    var currentDate = getDate.getFullYear() + "-" + ("0" + (getDate.getMonth() + 1)).slice(-2) + "-" + ("0" + getDate.getDate()).slice(-2);
    console.log("current,", dateFormat(currentDate, "yyyy-mm-dd"));
    // dateFormat(currentDate, "yyyy-mm-dd")
    this.setState({ startDate: dateFormat(data.startDate, "yyyy-mm-dd"), endDate: dateFormat(data.endDate, "yyyy-mm-dd") })
    Axios({
      method: "post",
      url: apiurl + "Nurse/nurseDashboard",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "5",
        from_date: dateFormat(data.startDate, "yyyy-mm-dd"),
        to_date: dateFormat(data.endDate, "yyyy-mm-dd"),
        current_date: dateFormat(currentDate, "yyyy-mm-dd")
      },
    }).then((response) => {
      if (response.data.data && response.data.data[0] && localStorage.nurse_language != 'ar') {
        const ApiData = response.data.data[0].dashboard;

        var TableData = [];

        response.data.data[0].today_appointments.map((val, index) => {
          // TableData.push({
          //   customername: val.PatientName,
          //   nursename: val.Nursename,
          //   dutyhours: val.working_hours,
          //   from_date: dateFormat(val.from_date, "dd-mmm-yy"),
          //   to_date: dateFormat(val.to_date, "dd-mmm-yy"),
          //   status: (val.CompletedDays ? val.CompletedDays : 0) + "/" + (val.NoOfDays ? val.NoOfDays : 0),
          //   totalcost: formatMoney(val.total_charge),
          //   check_in_time: val.check_in_time ? val.check_in_time : '-',
          //   isCheckin: val.isCheckin,

          //   id: index,
          // });
            TableData.push({
             customername: val.PatientName,
             appointmenttype: val.AppointmentType == 1 ?
             <div>
               <img width={35} src={logos} alt="" />
               <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
             </div> :
             val.AppointmentType == 2 ?
               "Walk-in" :
               val.AppointmentType == 3 ?
                 <div>
                   <img width={35} src={logos} alt="" />
                   <span style={{ marginLeft: '5px' }}>App</span>
                 </div> : "",
          paymenttype: val.PaymentType == 1 ? "Direct Academy" :
             val.PaymentType == 2 ?
               <div>
                 <img width={35} src={logos} alt="" />
                 <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
                 <span style={{ marginLeft: '5px' }}>Link</span>
               </div> :
               val.PaymentType == 3 ?
                 <div>
                   <img width={35} src={logos} alt="" />
                   <span style={{ marginLeft: '5px' }}>App</span>
                 </div> : "",
             paymentstatus: val.PaymentStatus == "Pending" ? <span style={{ color: '#FF0068' }}>{val.PaymentStatus}</span> : val.PaymentStatus,
             nursename: val.PaymentStatus == "Pending" ? "-" : val.Nursename || '',
             dutyhours: val.PaymentStatus == "Pending" ? "-" : val.working_hours || '',
             from_date: val.PaymentStatus == "Pending" ? "-" : dateFormat(val.from_date, "dd-mmm-yy") || '',
             to_date: val.PaymentStatus == "Pending" ? "-" : dateFormat(val.to_date, "dd-mmm-yy") || '',
             totalcost: val.PaymentStatus == "Pending" ? "-" : formatMoney(val.total_charge) || '',
             view: '',
            id: index,
           });
        });
        this.setState({
          nursehired: ApiData.nursehired,
          completedbooking: ApiData.completedbooking,
          ongoingbooking: ApiData.ongoingbooking,
          bookingyettostart: ApiData.bookingyettostart,
          canceledbooking: ApiData.canceledbooking,
          revenue: ApiData.total_revenue,
          TableData,
          ViewData: response.data.data[0].today_appointments,
          props_loading: false,
        });
      } else if (response.data.data && response.data.data[0] && localStorage.nurse_language == 'ar') {
        const ApiData = response.data.data[0].dashboard;

        var TableData = [];

        response.data.data[0].today_appointments.map((val, index) => {
          // TableData.push({
          //   customername: val.PatientName,
          //   patientname: val.PatientName,
          //   nursename: val.Nursename,
          //   dutyhours: val.working_hours,
          //   from_date: dateFormat(val.from_date, "dd-mmm-yy"),
          //   to_date: dateFormat(val.to_date, "dd-mmm-yy"),
          //   status: (val.CompletedDays ? val.CompletedDays : 0) + "/" + (val.NoOfDays ? val.NoOfDays : 0),
          //   totalcost: formatMoney(val.total_charge),
          //   check_in_time: val.check_in_time ? val.check_in_time : '-',
          //   // isCheckin: val.isCheckin,

          //   id: index,
          // });
          TableData.push({
            view: '',
            customername: val.PatientName,
            appointmenttype: val.AppointmentType == 1 ?
            <div>
              <img width={35} src={logos} alt="" />
              <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
            </div> :
            val.AppointmentType == 2 ?
              "Walk-in" :
              val.AppointmentType == 3 ?
                <div>
                  <img width={35} src={logos} alt="" />
                  <span style={{ marginLeft: '5px' }}>App</span>
                </div> : "",
          paymenttype: val.PaymentType == 1 ? "Direct Academy" :
            val.PaymentType == 2 ?
              <div>
                <img width={35} src={logos} alt="" />
                <img style={{ marginLeft: '5px' }} width={10} src={walkin} alt="" />
                <span style={{ marginLeft: '5px' }}>Link</span>
              </div> :
              val.PaymentType == 3 ?
                <div>
                  <img width={35} src={logos} alt="" />
                  <span style={{ marginLeft: '5px' }}>App</span>
                </div> : "",
            paymentstatus: val.PaymentStatus == "Pending" ? <span style={{ color: '#FF0068' }}>{val.PaymentStatus}</span> : val.PaymentStatus,
            nursename: val.PaymentStatus == "Pending" ? "-" : val.Nursename || '',
            dutyhours: val.PaymentStatus == "Pending" ? "-" : val.working_hours || '',
            from_date: val.PaymentStatus == "Pending" ? "-" : dateFormat(val.from_date, "dd-mmm-yy") || '',
            to_date: val.PaymentStatus == "Pending" ? "-" : dateFormat(val.to_date, "dd-mmm-yy") || '',
            totalcost: val.PaymentStatus == "Pending" ? "-" : formatMoney(val.total_charge) || '',
            
            id: index,
          });
        });
        debugger;
        let a = TableData
        this.setState({
          nursehired: ApiData.nursehired,
          completedbooking: ApiData.completedbooking,
          ongoingbooking: ApiData.ongoingbooking,
          bookingyettostart: ApiData.bookingyettostart,
          canceledbooking: ApiData.canceledbooking,
          revenue: ApiData.total_revenue,
          TableData,
          ViewData: response.data.data[0].today_appointments,
          props_loading: false,
        });
      } else {
        this.setState({
          TableData: [],
          props_loading: false
        });
      }
    });
  };

  render() {
    const { t } = this.props
    return (
      <div className="VendorDetailsDiv">
        <Paper className="paper">
          <div className="nurse_dashboard_buttons_wrap">
            <Card
              component={NavLink}
              to="/Home/nursehired"
              className="nurse_button1 nurse_button_common_styles"
            >
              <p className="nurse_button_text"> {t('total_bookings')}</p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.nursehired ? this.state.nursehired : 0}
                </p>
              </div>
            </Card>
            <Card
              component={NavLink}
              to="/Home/completedbooking"
              className="nurse_button2 nurse_button_common_styles"
            >
              <p className="nurse_button_text">{t('completed_bookings')}</p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.completedbooking ? this.state.completedbooking : 0}
                </p>
              </div>
            </Card>
            <Card
              component={NavLink}
              to="/Home/ongoingbooking"
              className="nurse_button3 nurse_button_common_styles"
            >
              <p className="nurse_button_text">{t('ongoing_bookings')}</p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.ongoingbooking ? this.state.ongoingbooking : 0}
                </p>
              </div>
            </Card>
            <Card
              component={NavLink}
              to="/Home/bookingyettostart"
              className="nurse_button4 nurse_button_common_styles"
            >
              <p className="nurse_button_text">{t('booking_yet_to_start')}</p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.bookingyettostart ? this.state.bookingyettostart : 0}
                </p>
              </div>
            </Card>

            {/* <Card
              component={NavLink}
              to="/Home/cancelledappointments"
              className="nurse_button5 nurse_button_common_styles"
            >
              <p className="nurse_button_text">{t('canceled_bookings')}</p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.canceledbooking ? this.state.canceledbooking : 0}
                </p>
              </div>
            </Card> */}
            <Card
              component={NavLink}
              to="/Home/revenue"
              className="nurse_button6 nurse_button_common_styles"
            >
              <p className="nurse_button_text">
                {t('revenue_with_kwd') === "Revenue (KWD)" ? (
                  <span>Revenue<span style={{ fontSize: "10px" }}> ({localStorage.getItem("nurse_language") === "en" ? "KWD" : "د.ك"})</span></span>
                ) : (t('revenue_with_kwd'))}
              </p>
              <div className="divider_container">
                <div className="divider_1px"></div>
              </div>
              <div className="nurse_dashboard_numeric_wrap">
                <p className="nurse_dash_numeric_value">
                  {this.state.revenue ? this.state.revenue?.toFixed(3) : "0"}
                </p>
              </div>
            </Card>
          </div>

          <Nursedetails
            TableData={this.state.TableData}
            props_loading={this.state.props_loading}
            ViewData={this.state.ViewData}
            CostofMonth={this.state.CostofMonth}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            t={t}
            i18n={this.props.i18n}
          />
        </Paper>
      </div>
    );
  }
}

export default Nurse_dash_page;
