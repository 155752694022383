import React, { Component } from "react";
import SignaturePad  from 'react-signature-pad'
import Button from "@material-ui/core/Button";
import "./Login.css"


export default class Signature extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
      }
    render ()
    {
        return (
            <div>
                <SignaturePad ref="signature_ref"  clearButton="false"   />
                <div style={{marginTop:'1rem',textAlign:'center'}}>
                    <Button
                            className="reject_btn"
                            onClick={() =>
                            this.refs.signature_ref.clear()
                            }
                        > Clear
                    </Button>
                    <Button
                        className="accept_btn"
                        onClick={()=>{
                            if(!this.refs.signature_ref.isEmpty())
                            {
                                var data =this.refs.signature_ref.toDataURL();
                                this.props.signatureSave(data)     
                            }
                           
                        }}
                        > Save                  
                    </Button>
                </div>
            </div>
        )
    }
}