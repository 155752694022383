import React from "react";
import "./BankCommission.css";
import MasterCard from "./../../Images/masterCard.png";
import ApplePay from "./../../Images/applePay.png";
import KNet from "./../../Images/kNet.png";
import { Checkbox } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Axios from "axios";
import { apiurl } from "../../App";
import { Spin } from "antd";


class BankCommission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creditCard: false,
      kNet: false,
      applePay: false,
      spinner: false,
    };
  }


  componentDidMount = () => {
    this.setState({ spinner: true });
    this.state.creditCard = this.props.EditData[0].credit_card;
    this.state.kNet = this.props.EditData[0].knet;
    this.state.applePay = this.props.EditData[0].apple_pay;
      this.setState({ spinner: false });
  }


  updateCommission = () => {
    if (this.state.creditCard == true || this.state.kNet == true ) {
      this.setState({ errorMsg: false });
      var updateValue = {
        trainingCenterId: localStorage.vendorId ? localStorage.vendorId : '',
        creditCard: this.state.creditCard == false ? 0 : 1,
        kNet: this.state.kNet == false ? 0 : 1,
        applePay: 0 ,
      };
      Axios({
        method: "POST",
        url: apiurl + "Sports/updateCommissionDetails",
        data: updateValue,
      })
        .then((response) => {
          if(response.data.status == 1){
            if (localStorage.training_center_language == 'ar') {
              this.props.handleopenSuccess("!تم تحديث عمولة البنك بنجاح", true);
            } else {
              this.props.handleopenSuccess("Bank Commission Updated Successfully!", true);
            }
          }else{
            if (localStorage.training_center_language == 'ar') {
              this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
            } else {
              this.props.handleopenSuccess("Bank Commission Updated Failed!", false);
            }
          }
          this.props.closemodal();
          this.props.ProfileGetApi();
        })
        .catch((error) => {
          if (localStorage.training_center_language == 'ar') {
            this.props.handleopenSuccess("!فشل تحديث عمولة البنك", false);
          } else {
            this.props.handleopenSuccess("Bank Commission Updated Failed!", false);
          }
        });
    } else {
      this.props.handleopenSuccess("Please Select any payment method", false);
    }
  };




  render = () => {
    const { t } = this.props;
    return (
      <>
        <Spin className="spinner_align" spinning={this.state.spinner}>
          <div className={`${this.props.i18n.language == "ar" ? "arCommissionManageDiv" : ""} commissionManageDiv`}>
            <div className="paymentMethodContainer">
              <div>
                <h1 className="paymentMethod">{t("paymentMethod")}</h1>
                <hr></hr>
              </div>
              <div className="imgContainer">
                <div className="imgChildContainer">
                  <img src={MasterCard} width={"20%"} alt="" />
                  <p className="color cardtype">{t("creditCard")}</p>
                  <Checkbox
                    checked={this.state.creditCard}
                    onChange={() => this.setState({ creditCard: !this.state.creditCard })}>
                  </Checkbox>
                </div>
                <div className="imgChildContainer">
                  <img src={KNet} width={"20%"} alt="" />
                  {/* <p className="color cardtype">{t('Knet')}</p> */}
                  <Checkbox
                    checked={this.state.kNet}
                    onChange={() => this.setState({ kNet: !this.state.kNet })}>
                  </Checkbox>
                </div>
                {/* <div className="imgChildContainer">
              <img src={ApplePay} width={"28%"} alt="" />
              <p className="color cardtype">{t('applePay')}</p>
              <Checkbox
                checked={this.state.applePay}
                onChange={() => this.setState({ applePay: !this.state.applePay })}>
              </Checkbox>
            </div> */}
              </div>
            </div>
            <div className="paymentMethodContainer">
              <div>
                <h1 className="paymentMethod">{t('commission')}</h1>
                <hr></hr>
              </div>
              <div className="commissionContainer">
                <div className="commisionDetails">
                  <h3 className="color">{t("creditCard")}</h3>
                  <h3 className="color">2.25%</h3>
                </div>
                <div className="knetCommisionDetails">
                  <h3 className="color">{t('Knet')}</h3>
                  <h3 className="color">0.150 KWD</h3>
                </div>
                {/* <div>
              <h3 className="color">{t('applePay')}</h3>
              <h3 className="color">3%</h3>
            </div> */}
              </div>
            </div>
            <Grid
              item
              xs={12}
              md={12}
              className="profile_cont"
              style={{ padding: "12px" }}
            >
              <div
                style={{
                  marginRight: "27px",
                  display: (localStorage.training_center_language == 'ar' ? 'flex' : ''),
                  gap: (localStorage.training_center_language == 'ar' ? '10px' : ''),
                  marginLeft: (localStorage.training_center_language == 'ar' ? '27px' : ''),
                }}>
                <Button
                  style={{ marginLeft: "30px" }}
                  className="cancel_button"
                  variant="contained"
                  onClick={this.props.onClose}
                >
                  {t("cancel")}
                </Button>


                <Button
                  className="update_button"
                  variant="contained"
                  color="primary"
                  onClick={this.updateCommission}
                >
                  {t("update")}
                </Button>
              </div>
            </Grid>
          </div>
        </Spin>
      </>
    );
  };
}


export default BankCommission;