// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from '@firebase/app';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import axios from "axios";

var firebaseConfig = {
  apiKey: "AIzaSyAGa1jn94UDEndijhgnjRnR9pnbUPCiO2U",
  authDomain: "fcm-tom.firebaseapp.com",
  projectId: "fcm-tom",
  storageBucket: "fcm-tom.firebasestorage.app",
  messagingSenderId: "153381906019",
  appId: "1:153381906019:web:cae9f64fb801d858a7fa56",
  measurementId: "G-6GCK1WMD3Y"
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export const requestForToken = () => {
  debugger;
  return getToken(messaging, { vapidKey: `BJdAv8yizSpWkj0GfatxtS3mqhsFc2K-t9bYpr5tmlDGjiRNJjGClId3lOjIQdGFWDETVBEMEOFrJ-X8YnEnPuM` })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client:', currentToken);
        localStorage.setItem("TokenFCM",currentToken)
        // sendToken(currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// const sendToken = (token) => {

//   var data = {
//     "vendor_id": localStorage.getItem("Doctor_id"),
//     "token":token
//   };
//   axios
//     .post("admin/insertvendorwebtoken", data)
//     .then((response) => {
//       console.log("update token response", response);
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// } 
