import React from "react";
import Axios from "axios";
import { apiurl } from "../../App";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import Button from "@material-ui/core/Button";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import Modalcomp from "../../helpers/ModalComp/Modalcomp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { notification } from "antd";
import VoiceImage from "../../Images/mic_img.svg";
import ReactSVG from "react-svg";
import UploadDocImage from "../../Images/upload_document.svg";
import More from "../../Images/more.svg";
import he from 'he';
import "./TicketMaster.css";
import PropTypes from "prop-types";
class TicketModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      menulist: [],
      menu: "",
      menu_error: false,
      remark: "",
      remark_error: false,
      imageupload_error: false,
      openRecordModal: false,
      audioDetails: {
        url: null,
        blob: null,
        chunks: null,
        duration: {
          h: 0,
          m: 0,
          s: 0,
        },
      },
      audio_record_file: [],
      audio_record: [],
      playRecord: false,
      typeoff: "",
      audio_url: "",
      audio_tr: false,
      vedio_tr: false,
      img_url: "",
      imagedata: [],
      imagepathname: "",
      audiopathname: "",
      buttonDisabled: false,
    };
  }

  componentDidMount() {
    let arr = document
      .getElementsByClassName("appbar_sideicons")[0]
      .getElementsByTagName("a");
    let menulist = [];
    for (let i = 0; i < arr.length; i++) {

      let spanElement = arr[i].getElementsByClassName("MuiListItemText-root")[0]
        .getElementsByTagName("span")[0];
      if (spanElement) {
        let itemName = spanElement.innerHTML.trim();
        let decodedItemName = he.decode(itemName);
        if (decodedItemName.toLowerCase() !== "ticketing") {
          menulist.push({
            name: decodedItemName,
            value: decodedItemName,
          });
        }
      }
    }
    this.setState({ menulist });
  }

  ticketSubmit() {
    if (this.state.buttonDisabled) return;
    this.setState({ buttonDisabled: true });
    let is_valid = true;
    if (this.state.menu == "") {
      this.setState({ menu_error: true });
      is_valid = false;
    }

    if (this.state.remark == "" && this.state.imagedata.length == 0 && this.state.audio_url == "") {
      this.setState({ remark_error: true });
      this.setState({ imageupload_error: true });
      is_valid = false;
    }
    // if (this.state.imagedata.length == 0 &&  this.state.audio_url =="") {
    //   this.setState({ imageupload_error: true });
    //   is_valid = false;

    // }

    if (is_valid) {
      this.ticketSave();
    }
    else {
      // Re-enable button if validation fails
      this.setState({ buttonDisabled: false });
    }
  }


  handleopenSuccess = (successmsg, issuccess) => {
    this.setState({ visible: true, successmsg, issuccess });
  };

  handleCloseSuccess = () => {
    this.setState({ visible: false });
  };
  ticketSave() {
    let now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    let currentTime = hours + ":" + minutes;
    let formdata = new FormData();
    formdata.append('image_files', this.state.imagedata.length != 0 ? this.state.imagedata?.split("?")[0] : '');
    formdata.append('audio_files', this.state.audio_url != '' ? this.state.audio_url?.split("?")[0] : '');
    formdata.set("screen_name", this.state.menu);
    formdata.set("remarks", this.state.remark);
    formdata.set("booked_time", currentTime);
    formdata.set("module_name", "Nurse");
    formdata.set(
      "vendor_id",
      localStorage.getItem("nurse_vendor_id")
        ? localStorage.getItem("nurse_vendor_id")
        : "11"
    );

    Axios({
      method: "post",
      url: apiurl + "admin/save_vendor_ticket",
      data: formdata,
    })
      .then((response) => {
        if (response.data.status == 1) {
          this.setState({ openRecordModal: false }, () => {
            this.props.ticketSuccess(response.data.data.ticket_no);
          });
        } else {
          notification.error({
            message: "Ticket added failed",
          });
        }
        this.setState({ buttonDisabled: false });
      })
      .catch((error) => {
        this.setState({ buttonDisabled: false });
      });
  }

  handleAudioStop(data) {
    this.setState({ audioDetails: data });
  }

  handleAudioUpload(file) {
    if (file) {
      file["name"] =
        "TOM-Suport-" +
        new Date().getDate() +
        "-" +
        new Date().getMonth() +
        "-" +
        new Date().getFullYear() +
        "-" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        "-" +
        new Date().getSeconds() +
        ".mp3";
      file["webkitRelativePath"] = "";
      file["lastModifiedDate"] = new Date();
      let filename =
        "TOM-Suport-" +
        new Date().getDate() +
        "-" +
        new Date().getMonth() +
        "-" +
        new Date().getFullYear() +
        "-" +
        new Date().getHours() +
        "-" +
        new Date().getMinutes() +
        "-" +
        new Date().getSeconds() +
        ".mp3";
      let audioFile = new File([file], filename);
      const formData = new FormData();
      formData.append('module_id', "5");
      formData.append('file', audioFile);
      formData.append('pathLocation', "SUPPORT/")


      Axios({
        method: "POST",
        url: apiurl + "admin/awsS3FileUpload",
        data: formData,

        onUploadProgress: (progressEvent) => {
          const percentage = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.setState({ setVar_Progress: percentage })
        },

      }).then((response) => {
        this.setState({
          audio_url: response.data.filepath.Location,
          audiopathname: response.data.filepath.privatePathUrl.split('/')[5],
          imageupload_error: false,
          remark_error: false,
          audio_tr: true,
          vedio_tr: false
        });
      });
    }
    this.setState({ openRecordModal: false });
  }

  handleCountDown(data) {
    console.log(data);
  }

  handleReset() {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    this.setState({ audioDetails: reset });
  }

  handleOpenAudioRecord = () => {
    const reset = {
      url: null,
      blob: null,
      chunks: null,
      duration: {
        h: 0,
        m: 0,
        s: 0,
      },
    };
    this.setState({ openRecordModal: true, audioDetails: reset });
  };
  handleClickclose = () => {
    this.setState({ openRecordModal: false }, () => {
      this.handleReset();
    });
  };

  handleClickPlayOpen = (data, type) => {
    if (type == "audio") {
      this.setState({ playRecord: true, audio_url: data, typeoff: type, audio_tr: true });
    } else if (type == "image") {
      this.setState({ img_url: data, playRecord: true, typeoff: type, vedio_tr: true, audio_tr: false });
    }
  };

  handleClickPlayclose = () => {
    this.setState({ playRecord: false });
  };

  fileSelectedHandler = (e) => {
    if (/\.(zip|zipx|jar|rar|7z|tgz|exe)$/i.test(e.target.value)) {
      alert(`Please do not select an ${"." + e.target.value.split(".")[(e.target.value.split(".").length - 1)]} file.`);
      e.target.value = "";
      return;
    }
    const formData = new FormData();
    formData.append('module_id', "5");
    formData.append('file', e.target.files[0]);
    formData.append('pathLocation', "SUPPORT/")

    Axios({
      method: "POST",
      url: apiurl + "admin/awsS3FileUpload",
      data: formData,

      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        this.setState({ setVar_Progressing: percentage })
      },
    }).then((response) => {
      if (response.data.filepath.Location != null) {
        this.setState({
          imagedata: response.data.filepath.Location, remark_error: false,
          imageupload_error: false,
        });
      }
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} ticket_add_div`}>
        <Grid container spacing={2} style={{ padding: "0px 5rem 0px 5rem", direction: this.props.i18n.language == "ar" ? "rtl" : "" }}>
          <Grid item md={12} sm={12}>
            <Labelbox
              className="label-boxss"
              labelname={t("tbl_head_screen_name")}
              type="select"
              valuelabel={"name"}
              valuebind={"value"}
              changeData={(data) =>
                this.setState({ menu: data, menu_error: false })
              }
              value={this.state.menu}
              error={this.state.menu_error}
              errmsg={t("pleaseSelectMenu")}
              dropdown={this.state.menulist}
            ></Labelbox>
          </Grid>
          <Grid item md={12} sm={12}>
            <Labelbox
              className="label-box"
              labelname={t("tbl_head_issue_raised_by_vendor")}
              type="textarea"
              changeData={(data) =>
                this.setState({ remark: data, remark_error: false, imageupload_error: false })
              }
              value={this.state.remark}
              error={this.state.remark_error}
              errmsg={t("pleaseEnterValidRemark")}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            <Grid container>
              <Grid md={5} sm={5}>
                <div style={{ textAlign: "center" }}>
                  {/*  */}
                  <ReactSVG
                    onClick={() => {
                      this.handleOpenAudioRecord();
                    }}
                    src={VoiceImage}
                    style={{ cursor: "pointer" }}
                  />
                  <div style={{ fontSize: "16px", color: "black" }}>
                    {" "}
                    {t("click_to_record_audio")}
                  </div>
                </div>
                {this.state.setVar_Progress > 0 &&
                  <div className="progress_container_audio">
                    <progress max="100" value={this.state.setVar_Progress}></progress>
                    <span>{`${this.state.setVar_Progress}%`}</span>
                  </div>
                }

                <div style={{ margin: "10px 0px" }}>
                  {this.state.audio_url != "" && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "14px",
                          margin: "10px 0px",
                          color: "#707070",
                        }}
                      >
                        <div style={{ width: "90%" }}>
                          {this.state.audiopathname}
                        </div>
                        <div style={{ width: "20%", textAlign: "center" }}>
                          <PlayArrowIcon
                            style={{
                              fill: "#510f30",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              this.handleClickPlayOpen(
                                this.state.audio_url,
                                "audio"
                              );
                            }}
                          ></PlayArrowIcon>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid
                md={2}
                sm={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "black",
                }}
              >
                {" "}
                {t("or")}{" "}
              </Grid>
              <Grid md={5} sm={5}>
                <div style={{ textAlign: "center", marginTop: "3px" }}>
                  <ReactSVG
                    onClick={() => {
                      document.getElementById("getFile").click();
                    }}
                    src={UploadDocImage}
                  />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    id="getFile"
                    accept="*/*"
                    onChange={(e) => {
                      this.fileSelectedHandler(e);
                    }}
                  />
                  <div style={{ fontSize: "16px", color: "black" }}>
                    {" "}
                    {t("click_to_upload_screenshot")}
                  </div>

                  {this.state.setVar_Progressing > 0 &&
                    <div className="progress_container">
                      <progress max="100" value={this.state.setVar_Progressing}></progress>
                      <span>{`${this.state.setVar_Progressing}%`}</span>
                    </div>
                  }

                  {this.state.imagedata != "" && (
                    <div
                      style={{
                        display: "flex",
                        fontSize: "14px",
                        margin: "10px 0px",
                        color: "#707070",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div style={{ width: "90%", wordWrap: "break-word" }}>
                        {this.state.imagepathname}
                      </div>
                      <div style={{ width: "20%", textAlign: "center" }}>
                        <a href={this.state.imagedata} target="_blank">
                          <img
                            src={More}
                            style={{
                              fill: "#F49C7A",
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          ></img>
                        </a>
                      </div>

                    </div>
                  )}
                </div>
              </Grid>
              {this.state.imageupload_error == false ? (
                ""
              ) : (
                <div
                  style={{ color: "#ff0068", fontSize: "12px", margin: "auto" }}
                >
                  {t("pleaseSelectAudioOrDocument")}
                </div>
              )}
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} style={{ textAlign: "center" }}>
            <Button
              className="ticketSubmit"
              onClick={() => {
                this.ticketSubmit();
              }}
              disabled={this.state.buttonDisabled}
            >
              {" "}
              {t("support_submit")}{" "}
            </Button>
          </Grid>
        </Grid>
        <Modalcomp
          visible={this.state.openRecordModal}
          xswidth="xs"
          title={t('voiceRecord')}
          clrchange="text_clr_change"
          closemodal={(e) => this.handleClickclose(e)}
          t={t}
          i18n={this.props.i18n}
        >
          <Recorder
            record={true}
            hideHeader={true}
            audioURL={this.state.audioDetails.url}
            showUIAudio
            handleAudioStop={(data) => this.handleAudioStop(data)}
            handleAudioUpload={(data) => this.handleAudioUpload(data)}
            handleCountDown={(data) => this.handleCountDown(data)}
            handleReset={() => this.handleReset()}
            mimeTypeToUseWhenRecording={`audio/webm`}
          />
        </Modalcomp>

        <Modalcomp
          visible={this.state.playRecord}
          xswidth="xs"
          title={this.state.audio_url != "" && this.state.audio_tr != false ? localStorage.nurse_language == 'ar' ? "تشغيل التسجيل" : "PLAY RECORDING" : localStorage.nurse_language == 'ar' ? "عرض الصورة" : "VIEW IMAGE"}
          clrchange="text_clr_change"
          closemodal={(e) => this.handleClickPlayclose(e)}
          t={t}
          i18n={this.props.i18n}
        >
          <div>
            {this.state.typeoff == "audio" ? (
              <div style={{ textAlign: "center" }}>
                {this.state.audio_url != "" && (
                  <audio controls src={this.state.audio_url}></audio>
                )}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                {this.state.img_url && (
                  <img
                    src={this.state.img_url}
                    id="preimg"
                    style={{ height: "300px", width: "auto" }}
                  />
                )}
              </div>
            )}
          </div>
        </Modalcomp>
      </div>
    );
  }
};

TicketModal.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
    t: PropTypes.func.isRequired,
  }),
};
TicketModal.defaultProps = {
  editData: null,
};
export default TicketModal;
