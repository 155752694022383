import React, { Component } from "react";
import "antd/dist/antd.css";
import { Input,notification } from "antd";
import { apiurl } from "../../App";
import Axios from "axios";
import dateFormat from "dateformat";
import RightArrowOnly from "../../Images/right-arrow-only.png";
import print from "../../Images/print.svg";
import pdf from "../../Images/pdf.svg";
import excel from "../../Images/excel.svg";
import ReactToPrint from "react-to-print";
import ReactExport from "react-data-export";
import PrintData from "./PrintData";
import ReactSVG from "react-svg";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Tablecomponent from "../../helpers/TableComponent/TableComp";
import { message } from "antd";
import NurseView from "../NurseView/Nurse_view";
import ArrowLeft from "@material-ui/icons/ChevronLeftSharp"
import { formatMoney } from "../../App";
import PDF from "../CommonPDF/PDF";
import downloadExcel from "../Common Excel/Excel";
var moment = require("moment");


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const headRows = [
  { id: "sno", label: "S.No", typeDt: 'sno' },
  { id: "customer", label: "Member", typeDt: 'str' },
  { id: "working_hours", label: "Duty Hours" },
  { id: "from_date", label: "From", type: "date", typeDt: 'date' },
  { id: "to_date", label: "To", type: "date", typeDt: 'date' },
  { id: "no_of_days", label: "No of Days" },
  { id: "totalcost", label: "Cost", typeDt: 'amt', type: "amt" },

];
class NurseHistoryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tableData: [],
      search: null,
      props_loading: true,
      fromDate: dateFormat(new Date(), "yyyy-mm-dd"),
      toDate: dateFormat(new Date(), "yyyy-mm-dd"),
      openview: false,
      viewdata: []
    };
  }
  Notification = () => {
    notification.warning({
      message: "No data found",
      placement: "topRight",
    });
  };

  componentDidMount() {
    this.getTableData();

    this.intervalId = setInterval(() => {
      this.getTableData();
    }, 120000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  generateprint = () => {
    this.setState({
      printComOpen: true,
    });
  };

  // get table data
  getTableData = () => {
    var tableData = [];
    let self = this;
    self.setState({ props_loading: true });
    Axios({
      method: "POST", //get api
      url: apiurl + "nurse/getnursehistory",
      data: {
        nursevendorId: localStorage.getItem("nurse_vendor_id") || "12",
        nurseId: this.props.nurseId, // prop coming from index.js
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        period: "Day",
      },
    })
      .then((response) => {
        response.data.data.map((val, index) => {
          tableData.push({
            customer: val.PatientName || "",
            working_hours: val.working_hours,
            from_date: dateFormat(val.from_date, "dd-mmm-yy"),
            to_date: dateFormat(val.to_date, "dd-mmm-yy"),
            no_of_days: val.NoOfDays ? val.NoOfDays : '',
            totalcost: val.total_charge ? formatMoney(val.total_charge) : '',
            id: index,
            nurseName: val.Nursename || "",
            nurseId: val.nurseId,
            address: val.address,
          });
          val['Dutiesofnurse'] = JSON.parse(val['Dutiesofnurse'])
        });
        self.setState({
          tableData: tableData,
          props_loading: false,
          totalData: response.data.data,
        });
      })
      .catch((error) => {
        message.error("List get failed.");
      });
  };

  handleJSON = (data) => {
    return JSON.parse(data) || [];
  };

  openmodal = () => {
    this.setState({ open: true });
  };
  onclosemodal = () => {
    this.setState({ open: false });
  };
  searchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
    this.setState({});
  };

  getRangeDate = (item) => {
    this.setState(
      {
        fromDate: dateFormat(item[0].startDate, "yyyy-mm-dd"),
        toDate: dateFormat(item[0].endDate, "yyyy-mm-dd"),
      },
      () => this.getTableData()
    );
  };

  modelopen = (data, id) => {
    if (data === "view") {
      this.setState({
        openview: true,
        viewdata: this.state.totalData[id],
      });
    }
  };

  closemodal = () => {
    this.setState({ openview: false });
  };

  render() {
    const { Search } = Input;
    const { t } = this.props;
    const heading = ((localStorage.nurse_language == 'ar') ? [
      { id: "", label: t('tbl_head_remarks') },
      { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
      { id: "no_of_days", label: t("no_of_days") },
      { id: "to_date", label: t("tbl_head_to") },
      { id: "from_date", label: t('tbl_head_from') },
      { id: "working_hours", label: t("tbl_head_duty_hours") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "", label: t("tbl_head_s_no") },
    ] : [
      { id: "", label: t("tbl_head_s_no") },
      { id: "customer", label: t('tbl_head_member') },
      { id: "working_hours", label: t("tbl_head_duty_hours") },
      { id: "from_date", label: t('tbl_head_from') },
      { id: "to_date", label: t("tbl_head_to") },
      { id: "no_of_days", label: t("no_of_days") },
      { id: "totalcost", label: t("tbl_head_fee_with_kwd") },
      { id: "", label: t('tbl_head_remarks') },
    ])
    const searchData = [];
    this.state.tableData.filter((data, index) => {
      if (this.state.search === null)
        searchData.push({
          customer: data.customer,
          working_hours: data.working_hours,
          from_date: data.from_date,
          to_date: data.to_date,
          no_of_days: data.no_of_days,
          totalcost: data.totalcost,
          id: data.id,
        });
      else if (
        (data.customer !== null &&
          data.customer
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.working_hours !== null &&
          data.working_hours.toString().includes(this.state.search.toString())) ||
        (data.from_date !== null &&
          data.from_date
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.to_date !== null &&
          data.to_date
            .toLowerCase()
            .includes(this.state.search.toLowerCase())) ||
        (data.no_of_days !== null &&
          data.no_of_days.toString().includes(this.state.search.toString())) ||
        (data.totalcost !== null &&
          data.totalcost.toString().includes(this.state.search.toString()))
      ) {
        searchData.push({
          customer: data.customer,
          working_hours: data.working_hours,
          from_date: data.from_date,
          to_date: data.to_date,
          no_of_days: data.no_of_days,
          totalcost: data.totalcost,
          id: data.id,
        });
      }
    });

    // EXCEL FUNCTION
    let multiDataSetbody = [];
    this.state.tableData.map((xldata, index) => {
      if (index % 2 !== 0) {
        multiDataSetbody.push([
          { value: index + 1, style: { alignment: { horizontal: "center" } } },
          { value: xldata.customer },
          { value: xldata.working_hours },
          { value: xldata.from_date },
          { value: xldata.to_date },
          { value: xldata.no_of_days },
          { value: xldata.totalcost },
        ]);
      } else {
        multiDataSetbody.push([
          {
            value: index + 1,
            style: {
              alignment: { horizontal: "center" },
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.customer,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.working_hours,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.from_date,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.to_date,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.no_of_days,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
          {
            value: xldata.totalcost,
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "e2e0e0" } },
            },
          },
        ]);
      }
    });
    const multiDataSet = [
      {
        columns: [
          {
            title: "S.No",
            width: { wpx: 35 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "Member",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "Duty Hours",
            width: { wch: 20 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "From",
            width: { wpx: 90 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "To",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "No. of Days",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
          {
            title: "Fee (KWD)",
            width: { wpx: 100 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "510f30" } },
              font: { color: { rgb: "FFFFFF" } }
            },
          },
        ],
        data: multiDataSetbody,
      },
    ];

    return (
      <div>
        <div className="title_dashboard">
          <div className="title_header" style={{ direction: localStorage.nurse_language == 'ar' ? "rtl" : "" }}>
            <span
              style={{
                cursor: "pointer",
                display: "inline-block",
              }}
              onClick={() => this.props.backToNurseTable()}
            >
              {localStorage.nurse_language == 'ar' ?
                <img src={RightArrowOnly} width={18} height={18} alt="arrow" style={{ cursor: "pointer" }} />
                :
                <ArrowLeft style={{
                  cursor: 'pointer',
                  fontSize: '4rem',
                  fill: 'black !important',
                  marginTop: '-3px'
                }} ></ArrowLeft>
              }
            </span>
            <span className="mr-4">{t("nurse_history_head")}</span>
            <span
              style={{
                color: "#510f30",
                fontSize: "20px",
                marginRight: "3px",
                marginLeft: localStorage.nurse_language == 'ar' ? "3px" : "-13px",
              }}
            >
              -
            </span>
            {console.log("this.props.nurseName", this.props.nurseName)}
            <span style={{ textTransform: 'uppercase' }}>{this.props.nurseName && this.props.nurseName}</span>
          </div>
          <div
            style={{ fontSize: "14px", display: "flex", alignItems: "center" }}
          >
            <Search
              placeholder={t('search')}
              onChange={(e) => this.searchChange(e)}
              style={{ width: 150, direction: this.props.i18n.language == "ar" ? "rtl" : "" }}
              className={`${this.props.i18n.language == 'ar' ? "order_1" : ""} mr-2 ml-2`}
            />
            <div className={`${this.props.i18n.language == 'ar' ? "order_0" : ""} icon_head`}>
              {/* <ReactSVG
                src={pdf}
                style={{ marginRight: "15px", marginLeft: "15px" }}
                onClick={this.generatepdf}
              /> */}

              <PDF
                weekMonthYearData={this.state.tableData}
                headRows={headRows}
                filename={"Nurse History"}
                headingTitle={'Nurse History'}
                lang={this.props.i18n.language}
              />



              {/* <ExcelFile
                element={
                  <ReactSVG src={excel} style={{ marginRight: "15px" }} />
                }
              >
                <ExcelSheet
                  dataSet={multiDataSet}
                  name="Nurse History Details"
                />
              </ExcelFile> */}

              <img
                onClick={() => {
                  downloadExcel('Nurse History', this.state.tableData, headRows, '', [])
                }}
                src={excel}
                style={{ cursor: 'pointer', height: '24px' }}
              />

              {this.state.tableData.length === 0 ? (
                <ReactSVG src={print} onClick={this.Notification} />
              ) : (
                <ReactToPrint
                  trigger={() => <ReactSVG src={print} />}
                  content={() => this.componentRef}
                />
              )}
            </div>
            <div style={{ display: "none" }}>
              <PrintData
                printtableData={this.state.tableData}
                ref={(el) => (this.componentRef = el)}
              />
            </div>
          </div>
        </div>
        <div className="nurse_history_table_div">
          <Tablecomponent
            heading={heading}
            rowdata={searchData}
            deleteopen={this.deleteopen}
            modelopen={(e, id) => this.modelopen(e, id)}
            props_loading={false}
            EditIcon={"close"}
            LocationOnIcon={"close"}
            HistoryIcon={"close"}
            DeleteIcon={"close"}
            t={t}
            i18n={this.props.i18n}
          />
        </div>


        <NurseView
          open={this.state.openview}
          ViewData={this.state.viewdata}
          onClose={this.closemodal}
          is_from_manage_nuser="true"
          t={t}
          i18n={this.props.i18n}
        />
      </div>
    );
  }
}
export default NurseHistoryTable;
