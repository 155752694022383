import React from "react";
import Grid from "@material-ui/core/Grid";
import Labelbox from "../../helpers/labelbox/labelbox";
import ValidationLibrary from "../../helpers/validationfunction";
import "./BankDetails.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { apiurl } from "../../App";
import PropTypes from "prop-types";

class BankDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankDetails: {
        bankName: {
          value: "",
          validation: [{ name: "required" }, { name: "Bank Name" }, { name: "alphaNumaricOnly" }, { name: "alphabetsOnly" }],
          error: null,
          errmsg: null,
        },
        accountName: {
          value: "",
          validation: [{ name: "required" }, { name: "Account Name" }, { name: "alphaNumaricOnly" }, { name: "alphabetsOnly" }],
          error: null,
          errmsg: null,
        },
        accountNumber: {
          value: "",
          validation: [{ name: "required" }, { name: "alphaNumaricOnly" }, { name: "allowNumaricOnlyandspace" }],
          error: null,
          errmsg: null,
        },
        branchArea: {
          value: "",
          validation: [{ name: "required" }, { name: "Branch Area" }, { name: "alphaNumaricOnly" }, { name: "alphabetsOnly" }],
          error: null,
          errmsg: null,
        },
        ibanNumber: {
          value: "",
          validation: [{ name: "required" }, { name: "alphaNumaricOnly" }],
          error: null,
          errmsg: null,
        },
      },
      apiBank: [],
    };
  }

  componentDidMount = () => {
    if (this.props.BankDetails.bank_name) {
      this.changeDynamic(this.props.BankDetails.bank_name, "bankName");
      this.changeDynamic(this.props.BankDetails.account_name, "accountName");
      this.changeDynamic(
        this.props.BankDetails.account_number,
        "accountNumber"
      );
      this.changeDynamic(this.props.BankDetails.branch_area, "branchArea");
      this.changeDynamic(this.props.BankDetails.iban, "ibanNumber");
    }
    this.props.ProfileGetApi()
  };

  changeDynamic = (data, key) => {
    if (data && data.length == 1 && data == " ") {
      data = data.trim()
    }
    let bankDetails = this.state.bankDetails;
    let errorcheck = ValidationLibrary.checkValidation(
      data,
      bankDetails[key].validation
    );
    bankDetails[key].value = data;
    bankDetails[key].error = !errorcheck.state;
    bankDetails[key].errmsg = errorcheck.msg;
    this.setState({ bankDetails });
  };

  checkValidation = () => {
    let bankDetails = this.state.bankDetails;
    let basicDetailsKeys = Object.keys(bankDetails);
    for (let i in basicDetailsKeys) {
      let errorcheck = ValidationLibrary.checkValidation(
        bankDetails[basicDetailsKeys[i]].value,
        bankDetails[basicDetailsKeys[i]].validation
      );
      bankDetails[basicDetailsKeys[i]].error = !errorcheck.state;
      bankDetails[basicDetailsKeys[i]].errmsg = errorcheck.msg;
    }
    let filtererr = basicDetailsKeys.filter(
      (obj) => bankDetails[obj].error === true
    );
    if (filtererr.length > 0 && this.state.startedSince === "") {
      filtererr.length > 0 ? this.setState({ error: true }) : this.setState({ invalidYear: true });
    } else if (this.state.bankDetails.bankName.value == "" || this.state.bankDetails.accountName.value == ""
      || this.state.bankDetails.accountNumber.value == "" || this.state.bankDetails.branchArea.value == ""
      || this.state.bankDetails.ibanNumber.value == "") {
    } else if (filtererr.length == 0) {
      this.setState({ error: false });
      this.onSubmitData();
    }
    this.setState({ bankDetails });
  };

  onSubmitData = () => {
    try {
      let data = {
        bank_name: this.state.bankDetails.bankName.value,
        account_name: this.state.bankDetails.accountName.value,
        account_no: this.state.bankDetails.accountNumber.value,
        branch_area: this.state.bankDetails.branchArea.value,
        iban: this.state.bankDetails.ibanNumber.value,
        dietvendorId: this.props.BankDetails.VendorId,
        module: "nurse"
      };
      axios({
        method: "POST",
        url: apiurl + "Diet/editVendorBankDetails",
        data: data,
      })
        .then((response) => {
          if (response.data.status === 1) {
            this.props.ProfileGetApi();
            this.props.bankofdetails();
            // this.props.handleopenSuccess("Bank details updated successfully!", true);
            this.props.onClose();
        } else {
            this.props.handleopenSuccess("Failed to update", false);
        }
        if(localStorage.nurse_language=='ar'){
          this.props.handleopenSuccess("!تم تحديث تفاصيل البنك بنجاح", true)
        }else{
          this.props.handleopenSuccess("Bank details updated successfully!", true)
        }
        // If you want to display the Arabic message:
        // this.props.handleopenSuccess("تم تحديث تفاصيل البنك بنجاح!", false);
        
        })
        
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  render = () => {
    const { t } = this.props;
    return (
      <div className={`${this.props.i18n.language == 'ar' ? "arabic_cls" : ""} bank_detail_div`} style={{ direction: this.props.i18n.language == "ar" ? "rtl" : "" }} >
        <Grid container dirction="row">
          <Grid item xs={12} md={6} className="key-label">
            <Labelbox
              type="text"
              labelname={<span>{t('bank_name')} {" "}
                <span className="contextIcon">*</span></span>}
              valuelabel={"Bank Name"}
              changeData={(data) => this.changeDynamic(data, "accountName")}
              value={this.state.bankDetails.accountName.value}
              error={this.state.bankDetails.accountName.error}
              errmsg={this.state.bankDetails.accountName.errmsg}
            />
          </Grid>
          <Grid item xs={12} md={6} className="key-label">
            <Labelbox
              type="text"
              labelname={<span>{t('account_name')}{" "}<span className="contextIcon">*</span></span>}
              valuelabel={"Account Name"}
              changeData={(data) => this.changeDynamic(data, "bankName")}
              value={this.state.bankDetails.bankName.value}
              error={this.state.bankDetails.bankName.error}
              errmsg={this.state.bankDetails.bankName.errmsg}
            />
          </Grid>
        </Grid>
        <Grid container dirction="row">
          <Grid item xs={12} md={6} className="key-label">
            <Labelbox
              type="number"
              labelname={<span>{t('account_number')}{" "}<span className="contextIcon">*</span></span>}
              valuelabel={"Account Number"}
              changeData={(data) => this.changeDynamic(data, "accountNumber")}
              value={this.state.bankDetails.accountNumber.value}
              error={this.state.bankDetails.accountNumber.error}
              errmsg={this.state.bankDetails.accountNumber.errmsg}
            />
          </Grid>
          <Grid item xs={12} md={6} className="key-label">
            <Labelbox
              type="text"
              labelname={<span>{t('branch_area')}{" "}<span className="contextIcon">*</span></span>}
              valuelabel={"Branch Area"}
              changeData={(data) => this.changeDynamic(data, "branchArea")}
              value={this.state.bankDetails.branchArea.value}
              error={this.state.bankDetails.branchArea.error}
              errmsg={this.state.bankDetails.branchArea.errmsg}
            />
          </Grid>
        </Grid>
        <Grid container dirction="row">
          <Grid item xs={12} md={6} className="key-label">
            <Labelbox
              type="text"
              labelname={<span>{t('iban')}{" "}<span className="contextIcon">*</span></span>}
              valuelabel={"IBAN"}
              changeData={(data) => this.changeDynamic(data, "ibanNumber")}
              value={this.state.bankDetails.ibanNumber.value}
              error={this.state.bankDetails.ibanNumber.error}
              errmsg={this.state.bankDetails.ibanNumber.errmsg}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              paddingRight: '28px'
            }}
          >
            <div className="bank_details_buttons">
              <div>
                <Button
                  className="cancel_button"
                  letiant="contained"
                  onClick={this.props.onClose}
                >
                  {t('cancel')}
                </Button>
              </div>
              <div>
                <Button
                  style={{ margin: this.props.i18n.language == "ar" ? "0px 27px" : "" }}
                  className="update_button"
                  letiant="contained"
                  color="primary"
                  onClick={this.checkValidation}
                >
                  {t('update')}
                </Button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };
};

BankDetails.propTypes = {
  i18n: PropTypes.shape({
    t: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
  }).isRequired,
  editData: PropTypes.object,
  editData: PropTypes.shape({
    id: PropTypes.string,
  }),
};
BankDetails.defaultProps = {
  editData: null,
};

export default BankDetails;
